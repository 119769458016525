<template>
  <div class="card social-widget">
    <div class="card-body">
      <div style="display:flex">
        <div class="search">
          <div class="block large-margins">
            <el-date-picker
                v-model="beforetime"
                type="datetime"
                placeholder="请选择开始日期"
                format="YYYY-MM-DD HH:mm:ss"
                date-format="MMM DD, YYYY"
                time-format="HH:mm"
            />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-date-picker
                v-model="aftertime"
                type="datetime"
                placeholder="请选择结束日期"
                format="YYYY-MM-DD HH:mm:ss"
                date-format="MMM DD, YYYY"
                time-format="HH:mm"
                style=""
            />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-input v-model="input" style="width: 240px" placeholder="请输入工单号" />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="value" placeholder="请选择分组列表" style="width: 200px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
              />
            </el-select>
          </div>

          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="value" placeholder="请选择工单状态" style="width: 200px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
              />
            </el-select>
          </div>


        </div>
        <!--        11111-->
        <div class="" style="text-align: center;display: flex;flex: 0.3 1 0%;  height: 36px; margin-top: 15px; margin-left: 5px;">
          <el-button type="primary" :icon="Search" class="search-but">搜索</el-button>
          <el-button type="warning" :icon="Search" class="search-but">重置</el-button>
        </div>
      </div>
      <div  class="large-margins">
        <el-button type="primary"  @click="dialogCreateFormVisible = true">创建</el-button>

      </div>
      <div class="large-margins">
        <el-table :data="tableData" border
                  style="width: 100%;margin-top:20px" :is-selected-all="isSelectedAll" @select="select"
                  @select-all="selectAll"  ref="innerTable"
        >
          <el-table-column align="center" type="selection" width="70" />

          <el-table-column align="center" prop="address" label="工单号" width="200"/>
          <el-table-column align="center" prop="address" label="工单名称" width="184"/>
          <el-table-column align="center" prop="address" label="创建日期" width="200"/>
          <el-table-column align="center" prop="address" label="分组列表" width="200"/>
          <el-table-column align="center" prop="address" label="过期日期" width="200"/>
          <el-table-column align="center" label="工单状态" width="180">
            <template #default="scope">
              <el-tag v-if="scope.row.status==1" type="success">正常</el-tag>
              <el-tag v-if="scope.row.status==0" type="danger">禁用</el-tag>
            </template>
          </el-table-column >


          <!--        操作-->
          <el-table-column align="center" label="操作" fixed="right" width="250">
            <template #default="scope">
              <el-button size="small" type="primary" @click="handlelook(scope.$index, scope.row)" text>
                查看
              </el-button>
              <el-button text size="small" type="primary"
                         @click="handleShare(scope.$index, scope.row)">
                分享
              </el-button>
              <el-button text size="small" type="primary"
                         @click="handlelook(scope.$index, scope.row)" >
                编辑
              </el-button>
              <el-button text size="small" type="primary"
                         @click="dialogTableVisible = true" >
                禁用
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>


  <el-dialog v-model="dialogCreateFormVisible" title="创建工单" width="1200">
    <div style="margin-left:30px">
      <el-form :model="form" >


        <el-form-item label="工单名称" >
          <el-input v-model="form.name" style="width: 240px" placeholder="请输入工单名称"/>
        </el-form-item>


        <el-form-item label="可用APP">
          <el-checkbox-group v-model="form.type">
            <el-checkbox value="Online activities" name="type">
              WhatApp
            </el-checkbox>
            <el-checkbox value="Promotion activities" name="type">
              Zalo
            </el-checkbox>
            <el-checkbox value="Offline activities" name="type">
              Line
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Telegrom
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              TikTok
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Instagram
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Facebook
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Twitter
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Messenger
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Meta Business
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Google Voice
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              BoTim
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Snapcaht
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              Line Business
            </el-checkbox>
            <el-checkbox value="Simple brand exposure" name="type">
              WEB
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="权限列表">
          <el-checkbox-group v-model="form.type">
            <el-checkbox value="Online activities" name="type">
              通讯录明细
            </el-checkbox>
            <el-checkbox value="Promotion activities" name="type">
              分析重复
            </el-checkbox>
            <el-checkbox value="Offline activities" name="type">
              移除账号
            </el-checkbox>

          </el-checkbox-group>
        </el-form-item>

          <el-form-item label="工单密码" >
            <el-input v-model="form.name" style="width: 240px" placeholder="请输入分享密码"/>
          </el-form-item>
          <el-form-item label="过期时间" >
            <el-date-picker
                v-model="date"
                type="date"
                placeholder="置零时间"
                clearable
            />
          </el-form-item>
        <el-form-item label="工单状态">
          <el-select v-model="form.region" placeholder="启用状态" style="width: 240px">
            <el-option label="正常" value="1" />
            <el-option label="禁用" value="2" />
          </el-select>
        </el-form-item>

      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogCreateFormVisible = false">保存</el-button>
        <el-button type="primary" @click="dialogCreateFormVisible = false">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogyqmGroupVisible" title="邀请码分组" width="1200">
    <yqmgroup />
  </el-dialog>
  <el-dialog v-model="dialogyqmShareVisible" title="分享邀请码" width="1200">
    <share />
  </el-dialog>

  <!--禁用-->
<!--  <el-dialog v-model="dialogTableVisible" title="是否确定停用邀请码" width="400">-->
<!--    <div class="large-margins">-->
<!--      <p>当邀请码被停用后：</p>-->
<!--      <p>系统将回收已分配的会话数；</p>-->
<!--      <p>客户端将关闭所有会话并退出；</p>-->
<!--      <p>邀请码分享链接依旧可查看记录；</p>-->
<!--      <p>将不再允许使用此邀请码。</p>-->
<!--      <br />-->
<!--      <div style="color: rgb(250, 173, 20);">-->
<!--        停用后将进入系统回收倒计时，在15天后自动清空邀请码相关数据。-->
<!--      </div>-->
<!--    </div>-->
<!--    <template #footer>-->
<!--      <div class="dialog-footer">-->
<!--        <el-button @click="dialogFormVisible = false">确认</el-button>-->
<!--        <el-button type="primary" @click="dialogFormVisible = false">-->
<!--          取消-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
<!--  </el-dialog>-->
</template>
<script>


export default {
  data() {
    return {
      beforetime:'',
      date:'',
      isSelectedAll:true,
      dialogyqmGroupVisible: false,
      dialogTableVisible:false,
      dialogFormVisible: false,
      dialogCreateFormVisible:false,
      dialogyqmShareVisible:false,
      aftertime:'',
      options:[
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
          disabled: true,
        },
        {
          value: 'Option3',
          label: 'Option3',
        },
        {
          value: 'Option4',
          label: 'Option4',
        },
        {
          value: 'Option5',
          label: 'Option5',
        },
      ],
      tableData:  [
        {
          date: '2016-05-04',
          name: 'Aleyna Kutzner',
          address: '1000',
          status:1,
        },
        {
          date: '2016-05-03',
          name: 'Helen Jacobi',
          address: '1000',
          status:0,
        },
        {
          date: '2016-05-02',
          name: 'Brandon Deckert',
          address: '1000',
          status:1,
        },
        {
          date: '2016-05-01',
          name: 'Margie Smith',
          address: '1000',
          status:0,
        },
      ],
      form:[
        {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
      ],
    };
  },
  components:{


  },
  watch: {

  },


  methods:{
    chageselectAll(){
      this.$refs.innerTable.clearSelection();
    },
    openGroup(){
      this.dialogyqmGroupVisible=true
    },
    textcopy(row) {
      console.log(row)
      // 获取要复制的文本内容
      const textToCopy = row; // 根据需要修改为相应的属性

      // 使用 Clipboard API 复制文本
      navigator.clipboard.writeText(textToCopy)
          .then(() => {
            // 复制成功的逻辑，您可以添加提示
            console.log('文本已复制到剪贴板：', textToCopy);
            this.$message.success('复制成功！');
          })
          .catch(err => {
            // 复制失败的逻辑
            console.error('复制失败', err);
            this.$message.error('复制失败！');
          });
    },
    handlelook(index,row){
      // console.log(row)
      this.$router.push({
        path:'/invitecode',
        query:row
      })
    },
    handleShare(index,row){
      this.dialogyqmShareVisible=true
    },
  }
}
</script>
<style scoped>
.large-margins{
  margin:15px 8px;
}
.search-but{
  width:120px
}
.el-input__wrapper {
  padding: 0 !important;
}
.el-input__prefix{
  padding-left:10px !important;
}
.search{
  display:flex;
  flex-flow: wrap; justify-content: start;
  flex: 1.6 1 0%; min-width: 350px;
}
.el-input--prefix .el-input__inner {
  padding-left: 30px;
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}
.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}
.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
}
.line {
  width: 1px;
  background-color: var(--el-border-color);
}
</style>