const http = require('@/utils/http')

const Api = {
    COMBO :"/combo",
    COMBO_PRICE :"/combo/price",
}

exports.Api = Api


exports.apiCombo = (data) => {
    return http.post(Api.COMBO, { data })
}


exports.apiComboPrice = (data) => {
    return http.post(Api.COMBO_PRICE, { data })
}



