<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img
          class="b-r-10 img-40"
          src="@/assets/images/user/user-dp.png"
          alt=""
      />
      <div class="media-body">
        <span>{{ username }}</span>
        <p class="mb-0 font-roboto">
          TongXinScrm <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="'/Payment'">
          <vue-feather type="user"></vue-feather>
          <span>充值 </span></router-link>
      </li>
      <li>
        <router-link :to="'/comboOrder'">
          <vue-feather type="mail"></vue-feather>
          <span>购买记录</span></router-link>
      </li>
      <li>
        <router-link :to="'/paymentOrder'">
          <vue-feather type="mail"></vue-feather>
          <span>充值记录</span></router-link>
      </li>
      <li @click="dialogUpdatePassword">
        <vue-feather type="mail"></vue-feather>
        <span>修改密码</span>
      </li>
      <li>
        <router-link :to="'/loginLog'">
          <vue-feather type="mail"></vue-feather>
          <span>登录日志</span></router-link>
      </li>

      <li>
        <a @click="logout">
          <vue-feather type="log-in"></vue-feather>
          <span>退出登录</span>
        </a>
      </li>
    </ul>
  </li>
  <updatePasswordDialog ref="RefUpdatePassword"/>

</template>

<script>
import { ref } from 'vue'

import {apiLogOutCode} from "@/api/login";
import updatePasswordDialog from "./updatePassword.vue";

export default {
  components: {
    updatePasswordDialog
  },
  data() {
    return {
      username: ''
    }
  },
  mounted() {
    this.username = localStorage.getItem('username');
  },
  methods: {
    logout: function () {
      apiLogOutCode({}) // Assuming code is password here
          .then(response => {

            this.$router.replace('/auth/login');
            localStorage.removeItem('User')

          })
          .catch(error => {
            // Handle error
            // console.error('Login failed:', error);
          });
    },
    dialogUpdatePassword() {
      this.$refs.RefUpdatePassword.visible = true
    }
  },
};
</script>
