<template>
  <el-dialog v-model="visible" title="训练语料" width="400px">
    <el-table
        :data="tableData"
        style="width: 100%"
        row-key="id"
        border
        lazy
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column align="center" prop="total" label="词条总数" />
      <el-table-column align="center" prop="trained" label="已训练" />
      <el-table-column align="center" prop="un_trained" label="未训练" />


    </el-table>
    <template slot="#empty">
      <el-empty description="未有可训练数据"/>
    </template>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="train" v-if="tableData[0].un_trained !=0">
          开始训练
        </el-button>
        <el-button type="danger" @click="visible = false" v-if="tableData[0].un_trained ==0">
          关闭(无训练数据)
        </el-button>
      </div>
    </template>

  </el-dialog>
</template>
<script>
import {apiCorpusTopicTrain} from "@/api/corpus_topic";

export default {
  data() {
    return {
      visible: false,
      dataItem:{},
      tableData:[
        {
          total:100,
          un_trained: 20,
          trained: 70,
        }
      ]
    }
  },
  methods: {
    openVisibleDialog(data) {
      this.visible = true;
      this.dataItem = data;
      if (data && data.corpus_count != undefined) {
        this.tableData = [
          {
            total: data.corpus_count,
            un_trained: data.corpus_count - data.corpus_trained_count,
            trained: data.corpus_trained_count,
          }
        ]


      }
    },
    train(){
      this.visible = false
      apiCorpusTopicTrain({
        id: this.dataItem.id
      }).then(res => {
        this.$message.success('开始训练,请等待...')
        this.$parent.fetchData()
      })
    }
  },
}
</script>

<style scoped lang="scss">

</style>