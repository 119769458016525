<template>
  <div class="card social-widget">
    <div class="card-header">
        <div class="radio-group-wrapper">
            <el-radio-group v-model="radio1" size="large">
              <el-radio-button label="Windows" value="Windows" />
              <el-radio-button label="MACOS" value="MACOS" />
            </el-radio-group>

        </div>
    </div>
    <div class="card-body">
      <div v-if="radio1 === 'Windows'" style="display:flex;display: -webkit-box;">

        <div><img src="@/assets/images/download/windows.png"  alt="" srcset=""></div>
        <div class="downloadTips">
          <h5>
            同心出海SCRM For Windows
          </h5>
          <div>
            安装一个软件即可实现多社交平台聚合聊天
            <br>
            全球多语言收发实时翻译 <br>
            聊天内容端对端加密会话 <br>
            一键实时查看通讯录明细 <br>
            支持最新Apple M1芯片 <br>
            <el-button round style="margin:10px 0">下载64位版本</el-button>
            <div>
              当前版本1.3.8 (更新2024.8.17)
            </div>
          </div>
        </div>
      </div>
      <div v-if="radio1 === 'MACOS'" style="display:flex;display: -webkit-box;">
          <div>
            <img src="@/assets/images/download/Macos.png"  alt="" srcset="">

          </div>
        <div class="downloadTips">
          <h5>
            同心SCRM For Windows
          </h5>
          <div>
            安装一个软件即可实现多社交平台聚合聊天
            <br>
            全球多语言收发实时翻译 <br>
            聊天内容端对端加密会话 <br>
            一键实时查看通讯录明细 <br>
            支持最新Apple M1芯片 <br>
            <el-button round style="margin:10px 5px 10px 0"> 下载MacOS版本 </el-button>
            <el-button round style="margin:10px 0">  下载Mac M1版本  </el-button>
            <div>
              当前版本1.3.8 (更新2024.8.17)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script >

export default {
  data() {
    return {
      radio1:"Windows",

    };
  },
  components:{

  },
  watch: {
    radio1(newValue, oldValue) {
      // 值变化时的操作
      console.log('radio1 的值从', oldValue, '变为', newValue);

      // 在这里可以执行需要的逻辑
    }
  },


  methods:{

  }
}
</script>
<style>
.radio-group-wrapper {
  display: flex;
  justify-content: center;
}
.downloadTips{
  -webkit-box-flex: .5;
  -ms-flex: .5;

  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
</style>