
export const defaultLocale = 'cn';
export const localeOptions=[
    { id:'cn',name:'简体中文',icon:'flag-icon-cn' },
    { id:'en',name:'English',icon:'flag-icon-us', short:'(US)'},
    { id:'de',name:'Deutsch',icon:'flag-icon-de' },
    { id:'es',name:'Español',icon:'flag-icon-es' },
    { id:'fr',name:'Français',icon:'flag-icon-fr' },
    { id:'pt',name:'Português',icon:'flag-icon-pt',short:'(BR)'},

    { id:'ae',name:'لعربية',icon:'flag-icon-ae',short:'(AE)' },
    
];
