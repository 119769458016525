<template>
  <el-dialog v-model="visible" title="是否确定停用邀请码" width="400">
    <div class="large-margins">
      <p>当邀请码被停用后：</p>
      <p>系统将回收已分配的会话数；</p>
      <p>客户端将关闭所有会话并退出；</p>
      <p>邀请码分享链接依旧可查看记录；</p>
      <p>将不再允许使用此邀请码。</p>
      <br/>
      <div style="color: rgb(250, 173, 20);">
        停用后将进入系统回收倒计时，在15天后自动清空邀请码相关数据。
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="save">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>


import {apiSubAccountRemove, apiSubAccountUpdate} from "@/api/sub_account";

export default {
  name: "disableSubAccountDialog",
  data() {
    return {
      id: 0,
      visible: false
    }
  },
  mounted() {
  },
  methods: {
    openVisibleDialog(row) {
      this.visible = true;
      this.id = row.id;
    },
    save() {
      // 确认停用��请码
      // this.$emit('disableSubAccount', this.id);
      apiSubAccountUpdate({
        id: this.id,
        status:2,
      }).then(response => {
        this.$message({
          type: "success",
          message: "停用邀请码成功"
        });
        this.$parent.fetchData();
        this.visible = false;

      })
    }
  }
}
</script>
<style scoped>
a {
  cursor: pointer;
}

.el-dialog__headerbtn .el-dialog__close {
  display: block !important;
}
</style>