<template>
  <el-dialog v-model="visible" :title="title" width="650px" :close-on-click-modal="false">
    <el-form :model="form" label-width="auto" style="max-width: 600px">
      <el-form-item label="词条属性">
        <el-select v-model="form.corpus_type" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="词条索引" width="320" >
        <el-tooltip placement="top">
          <template #content> 示例：(关键字1|关键字2|关键字3)<hr>
            你叫什么？| 你叫什么名字|你是谁？
          </template>
          <el-input type="textarea" autosize v-model="form.title" placeholder=""/>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" >
        <el-input  v-model="form.description" placeholder=""/>
      </el-form-item>
      <el-form-item label="内容" >
        <el-input  type="textarea"  rows="10" v-model="form.content" placeholder=""/>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="save">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import {apiCorpusCreate, apiCorpusUpdate} from "@/api/corpus";

export default {
  data() {
    return {
      visible: false,
      handleType: 'create',
      title: "创建词条",
      dataItem: {
        id: 0
      },
      form: {
        content:'',
        corpus_topic_id:'',
        title:'',
        description:'',
        status:1,
        corpus_type:1,
      },
      status: [
        {value: 1, label: '正常'},
        {value: 2, label: '已禁用'}
      ],
      options: [
        {
          value: 1,
          label: "人物档案",
          count: 0,
        },
        {
          value: 2,
          label: "业务背景",
          count: 0,
        },
        {
          value: 3,
          label: "主观意见",
          count: 0,
        },
        {
          value: 4,
          label: "闲聊话术",
          count: 0,
        },
      ],
    }
  },
  methods: {
    openVisibleDialog(data) {
      this.visible = true;
      console.log(data.id)
      if(data && data.id != undefined){
        this.form = data;
        this.handleType = 'update'
        this.title = "编辑词条";
      }
      if(data && data.id === undefined){
          this.handleType = 'create'
          this.title = "创建词条";
      }


    },
    save() {
      if(this.form && this.form.id != undefined){
        apiCorpusUpdate(this.form).then(res => {
          this.$message({
            type: 'success',
            message: '更新成功!'
          });
          this.visible = false;
          this.$parent.fetchData();
          this.resetForm()
        })
      }else{
        this.form.corpus_topic_id = parseInt(this.$parent.corpus_topic.id);
        apiCorpusCreate(this.form).then(res => {
          this.$message({
            type: 'success',
            message: '创建成功!'
          });
          this.visible = false;
          this.$parent.fetchData();
          this.resetForm()
        })
      }
    },
    resetForm() {
      this.form = {
        content:'',
        corpus_topic_id:'',
        title:'',
        description:'',
        status:1,
        corpus_type:1,
      }
    }
  },
}
</script>

<style scoped lang="scss">
.el-input {
  --el-input-width: 350px;
}

.el-select {
  --el-select-width: 220px;
}
</style>