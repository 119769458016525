export var menuItems={
  "data": [
    {
      "headTitle1": "General",
      "headTitle2": "Dashboards,Widgets & Layout.",
      "type": "headtitle"
    },

    {
      "path": "/",
      "title": "主页",
      "icon": "stroke-home",
      "iconf":"fill-home",
      "type": "link",
      "active": false
    },
    {
      "path": "/subAccount/index",
      "title": "邀请码列表",
      "icon": "stroke-board",
      "iconf":"fill-widget",
      "type": "link",
      "active": false,
    },

    {
      "path": "/contact",
      "title": "粉丝明细",
      "icon": "stroke-social",
      "iconf":"fill-to-do",
      "type": "link",
      "active": false,
    },
    {
      "path": "/Reply",
      "title": "AI助手",
      "icon": "stroke-chat",
      "iconf":"fill-to-do",
      "type": "link",
      "active": false,
    },
    {
      "path": "/Download",
      "title": "下载软件",
      "icon": "stroke-file",
      "iconf":"fill-ecommerce",
      "type": "link",
      "active": false,
    },


    {
      "path": "/Pricing",
      "title": "购买套餐",
      "icon": "stroke-ui-kits",
      "iconf":"fill-ui-kits",
      "type": "link",
      "active": false,
    },
    // {
    //   "path": "/Document",
    //   "title": "文档中心",
    //   "icon": "stroke-board",
    //   "iconf":"fill-board",
    //   "type": "link",
    //   "active": false,
    // },
    {
      "path": "/Payment",
      "title": "在线充值",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "type": "link",
      "active": false,
    },

  ]
}