<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>购买端口</h5>
          <div class="card-header-right">
            <ul class="list-unstyled">
              <li><!---->
                <el-button type="primary" @click="handleSessionBuy(5)">购买端口</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body row pricing-block">
          <div class="col-lg-3 col-sm-6" v-for="item in comboList.session">
            <div class="pricingtable">
              <div class="pricingtable-header"><h3 class="title">{{ item.description }}</h3></div>
              <div class="price-value"><span class="currency">$</span><span class="amount">{{ item.price }}</span><span
                  class="duration">/月</span></div>
              <ul class="pricing-content">
                <li>谷歌/DeepL 无限使用</li>
                <li>不限字数 多设备登录 支持数据分析</li>
              </ul>
              <div class="pricingtable-signup">
                <span @click="handleSessionBuy(item.limit)" class="btn btn-primary btn-lg">
                  立即购买
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-header"><h5>字数型</h5></div>
        <div class="card-body pricing-content">
          <div class="row g-sm-4 g-3">
            <div class="col-lg-3 col-sm-6" v-for="item in comboList.translate">
              <div class="card text-center pricing-simple">
                <div class="card-body">
                  <h3>{{ item.name }}</h3>
                  <h1>${{ item.price }}</h1>
                  <h6 class="mb-0">{{ item.description }}</h6>
                </div>
                <span class="btn btn-lg btn-primary btn-block" @click="handleTranslateBuy(item)">立即购买</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header"><h5>AI助手</h5></div>
        <div class="card-body pricing-content">
          <div class="row g-sm-4 g-3">
            <div class="col-lg-3 col-sm-6" v-for="item in comboList.llm">
              <div class="card text-center pricing-simple">
                <div class="card-body">
                  <h3>{{ item.name }}</h3>
                  <h1>${{ item.price }}</h1>
                  <h6 class="mb-0">{{ item.description }}</h6>
                </div>
                <span class="btn btn-lg btn-primary btn-block" @click="handleLlmBuy(item)">立即购买</span>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <sessionDialog ref="sessionDialog"></sessionDialog>
</template>
<script>

import {apiCombo, apiComboPrice} from "@/api/combo";
import sessionDialog from "@/pages/pricing/sessionDialog.vue";
import {apiCombosOrderLlm, apiCombosOrderTranslate} from "@/api/comboOrder";

export default {
  data() {
    return {
      dialogCreateFormVisible: false,
      form: [
        {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
      ],
      comboList: {
        session: [],
        translate: [],
        llm: []
      },
      comboPrice: [],
    }

  },
  components: {
    sessionDialog
  },
  mounted() {
    this.fetchPComboPrice()
    this.fetchData()
  },
  methods: {
    buy() {
      this.dialogCreateFormVisible = true
    },
    fetchData() {
      apiCombo().then(r => {
        this.comboList = r
      })
    },
    fetchPComboPrice() {
      apiComboPrice().then(r => {
        this.comboPrice = r
      })
    },
    handleSessionBuy(limit) {
      this.$refs.sessionDialog.dialogVisible()
      this.$refs.sessionDialog.form.limit = limit
    },
    handleTranslateBuy(item) {
      this.$confirm('即将购买' + item.name, '购买字符额度', {
        autofocus: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiCombosOrderTranslate({
          combo_id: item.id
        }).then(r => {
          console.log(r)
          this.$message({
            type: 'success',
            message: '购买成功'
          })
        })
      })

    },
    handleLlmBuy(item) {
      this.$confirm('即将购买' + item.name, '购买AI助手额度', {
        autofocus: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiCombosOrderLlm({
          combo_id: item.id
        }).then(r => {
          console.log(r)
          this.$message({
            type: 'success',
            message: '购买成功'
          })
        })
      })

    },


  }
}
</script>
<style scoped>

.el-input--prefix .el-input__inner {
  padding-left: 30px;
}

.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}

.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}

.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
}

.line {
  width: 1px;
  background-color: var(--el-border-color);
}

.amount {
  font-size: 35px !important;
}
.card .card-header .card-header-right{
  top:5px !important;
}
</style>