<template>
  <div class="header-logo-wrapper col-auto p-0">
    <div class="logo-wrapper">
      <router-link to="/">
        <div style="display: flex;justify-content: center;align-items: center;padding: 9px;">
          <img class="img-fluid for-light" src="@/assets/images/logo/logo-icon.png" alt="looginpage"
               style="width: 40px;">
          <div style="
    display: flex;
    flex-direction: column; /* 垂直排列子元素 */
    align-items: flex-start; /* 左对齐 */
    justify-content: space-between;
    align-content: space-between;
    margin-left: 6px;
    background-image: linear-gradient(90deg, #00c4cc, #7d71ff, #6952fa);
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    -webkit-background-clip: text;
    letter-spacing: 0.5px;

"><span style="
    font-size: 18px;
    font-weight: bold;
    color: #54ba4a;
        letter-spacing: 3.5px;
"> 同心出海 </span><span style="
    display: flex;
    font-weight: 400;
    letter-spacing: 3.5px;
"> SCRM跨境聚合应用平台 </span></div>
        </div>

      </router-link>
    </div>
    <div class="toggle-sidebar" @click="toggle_sidebar">
      <vue-feather
        class="status_toggle middle sidebar-toggle"
        type="align-center"
        id="sidebar-toggle"
      ></vue-feather>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'Logo',
    methods: {
      toggle_sidebar() {
        this.$store.dispatch('menu/opensidebar');
      },
    },
  };
</script>
