const http = require('@/utils/http')

const Api = {
    CORPUS_TOPIC: "/corpus_topic",
    CORPUS_TOPIC_CREATE: "/corpus_topic/create",
    CORPUS_TOPIC_UPDATE: "/corpus_topic/update",
    CORPUS_TOPIC_DELETE: "/corpus_topic/delete",
    CORPUS_TOPIC_TRAIN: "/corpus_topic/train"
}

exports.Api = Api


exports.apiCorpusTopic = (data) => {
    return http.post(Api.CORPUS_TOPIC, {data})
}


exports.apiCorpusTopicCreate = (data) => {
    return http.post(Api.CORPUS_TOPIC_CREATE, {data})
}


exports.apiCorpusTopicUpdate = (data) => {
    return http.post(Api.CORPUS_TOPIC_UPDATE, {data})
}


exports.apiCorpusTopicDelete = (data) => {
    return http.post(Api.CORPUS_TOPIC_DELETE, {data})
}


exports.apiCorpusTopicTrain = (data) => {
    return http.post(Api.CORPUS_TOPIC_TRAIN, {data})
}

