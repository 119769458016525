import {getStorage, setStorage} from "@/utils/cache/storage";


const StorageKey = {
    Authorization: 'Authorization',
    SessionTranslateConfig:'session_translate_config_',

}

export const getToken = () => {
    // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
    return getStorage(StorageKey.Authorization)
}


export const setToken = (token) => {
    return setStorage(StorageKey.Authorization, token)
}

export const removeToken = () => {
    return setStorage(StorageKey.Authorization, '')
}