
<template>
  <div class="card social-widget" style="min-height: 70vh;">
    <div class="card-body">
      <el-table :data="tableData"  empty-text="暂无数据" style="width: 100%;min-height: 453px;">
        <el-table-column prop="order_id" label="订单号" width="260px"/>
        <el-table-column prop="amount" label="支付金额">
          <template #default="scope">
            <span>${{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_type" label="类型">
          <template #default="scope">
            <el-tag v-if="scope.row.combo_type === 1">端口</el-tag>
            <el-tag v-if="scope.row.combo_type === 2">字符</el-tag>
            <el-tag v-if="scope.row.combo_type === 3">AI助手</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="day_count" label="天数">
          <template #default="scope">
            <span v-if="scope.row.combo_type === 1">{{scope.row.day_count}}</span>
            <span v-if="scope.row.combo_type !== 1">-</span>
          </template>
        </el-table-column>
        <el-table-column prop="item_limit" label="数量"/>
        <el-table-column prop="status" label="订单状态">
          <template #default="scope">
            <el-tag :type="scope.row.status === 2?'success' : 'warning'">{{scope.row.status === 2? '已完成' : '未完成'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="时间" width="180px"/>
      </el-table>


      <template slot="#empty">
        <el-empty description="description"/>
      </template>

      <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"
                          layout="prev, pager, next"
                          :total="total"/>
      </span>
    </div>
  </div>

</template>

<script >
import {apiPaymentOrder} from "@/api/paymentOrder";
import {apiCombosOrder} from "@/api/comboOrder";

export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      apiCombosOrder({
        page_size: this.pageSize,
        page: this.currentPage,
      }).then(response => {
        const {list, total} = response
        this.tableData = list
        this.total = total
      })
    },
    handlePrevClick() {
    },
    handleNextClick() {
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    }
  }
}
</script>
