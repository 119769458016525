<template>
<div class="col-sm-6 col-xl-3 col-lg-6">
    <div class="card profile-box">
        <div class="card-body">
            <div class="media">
                <div class="media-body">
                    <div class="greeting-user">
                        <h4 class="f-w-600 mb-0">Welcome to cuba</h4>
                        <p>Here whats happing in your account today</p>
                        <div class="whatsnew-btn">
                            <a class="btn btn-outline-white">Whats New !</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cartoon">
                <img class="img-fluid" src="@/assets/images/dashboard/cartoon.svg" alt="vector women with leptop" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            interval: "",
            greeting: "",
            time: "",
        };
    },
    mounted() {

    },
    unmounted() {
        clearTimeout(this.interval);
    },
};
</script>
<style>
.cartoon{

}
.profile-box .cartoon {
  position: absolute;
  bottom: -1.2rem;
  right: -40px;
  -webkit-animation: bounce-effect 5s ease-in infinite;
  animation: bounce-effect 5s ease-in infinite;
}
</style>