const state = {
    payment_amount: 0, // 存储支付金额
};

const mutations = {
    setPaymentAmount: (state, amount) => {
        state.payment_amount = amount; // 设置支付金额
    },
};

const getters = {
    getPaymentAmount: (state) => {
        return state.payment_amount; // 获取支付金额
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
