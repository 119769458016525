<template>
  <el-dialog v-model="visible" :title="'联系人明细：'+rowData.session_name" width="80%"    destroy-on-close>
    <el-table :data="tableData" border
              style="width: 100%;margin-top:20px" :is-selected-all="isSelectedAll" @select="select"
              @select-all="selectAll"  ref="innerTable"
    >
<!--      <el-table-column align="center" type="selection" width="70" />-->

<!--      <el-table-column align="center" prop="id" label="序号" width="160"/>-->
<!--      <el-table-column align="center" prop="repeat_count" label="重复次数" width="180"/>-->
      <el-table-column align="center" label="APP" width="180">
        <template #default="scope">
          <el-tag v-if="scope.row.platform_code==1" type="success">Whatsapp</el-tag>
          <el-tag v-if="scope.row.platform_code==2" type="success">Telegram</el-tag>
          <el-tag v-if="scope.row.platform_code==3" type="success">Line</el-tag>
          <el-tag v-if="scope.row.platform_code==4" type="success">Facebook</el-tag>
        </template>
      </el-table-column >
      <el-table-column align="center" prop="username" label="用户名" />
      <el-table-column align="center" prop="avatar" label="头像" width="120">
        <template #default="scope">
          <el-avatar
              :src="scope.row.avatar"
          >
            <template #default>
              <img src="@/assets/images/user/user.png"/>
            </template>
          </el-avatar>
        </template>
      </el-table-column>
      <el-table-column align="center" label="启用状态" width="180">
        <template #default="scope">
          <el-tag v-if="scope.row.status==1" type="success">正常</el-tag>
          <el-tag v-if="scope.row.status==2" type="danger">禁用</el-tag>
        </template>
      </el-table-column >
      <el-table-column align="center" prop="nickname" label="昵称" width="180"/>
      <el-table-column align="center" prop="created_at" label="创建时间" width="180"/>
      <el-table-column align="center"  label="来源" width="180">
        <template #default="scope">
          <el-tag v-if="scope.row.source==1" type="success">自来</el-tag>
          <el-tag v-if="scope.row.source==2" type="success">导入</el-tag>
        </template>
      </el-table-column >
    </el-table>
    <template slot="#empty">
      <el-empty description="description"/>
    </template>

    <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"
                          layout="prev, pager, next"
                          :total="total"/>
      </span>
  </el-dialog>
</template>
<script>
import {apiSubAccountSessionContact} from "@/api/sub_account_session";

export default {
  name: 'contactsDialog',
  data() {
    return {
      visible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      requestParams: {
        sub_account_session_id:""
      },
      rowData:{}
    };
  },
  methods: {
    openVisibleDialog(row) {
      this.visible = true;
      this.requestParams.sub_account_session_id = row.id
      this.rowData  = row
      console.log(row)
      this.fetchData()
    },
    fetchData() {
      // this.tableData = []
      apiSubAccountSessionContact({
        page_size: this.pageSize,
        page:this.currentPage,
        sub_account_session_id:this.requestParams.sub_account_session_id
      }).then(response => {
        const {list, total } = response
        this.tableData = list
        this.total = total
      })
    },
    handlePrevClick() {},
    handleNextClick() {},
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    }
  },
}

</script>

<style scoped lang="scss">

</style>