<template>


  <div class="col-xl-3 col-sm-4">
    <div class="card social-widget">
      <div class="card-body">
        <div style="margin: 20px;margin-bottom: 0px;">
          <div style="margin-bottom: 5px;font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;">
            <div>
              账户余额
            </div>
            <div class="media " style="font-size: 32px;color: #51bb25;};position: absolute;font-weight: 800; right:35px;margin-top:-10px">
              <div class="col-sm-6 col-md-4 col-xl-3">
                <i  class="fa fa-folder-open"></i>
              </div>
            </div>
          </div>
          <div style="display: flex;margin:10px 0px;">
            <div style="line-height: 36px;font-size:25px;font-weight:500">
              ${{ userinfo.balance ?? 0 }}
            </div>
            <div>

              <div data-v-54df109c="" class="font-roboto" style="font-size: 12px; line-height: 36px; position: absolute; padding-left: 5px; color: rgba(43, 43, 43, 0.54);">
                <i data-v-54df109c="" class="el-tooltip el-icon-info" aria-describedby="el-tooltip-1044" tabindex="0" style="font-size: 16px;"></i>
              </div>

            </div>
          </div>
          <div style="font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;    justify-content: end;">
            <router-link to="/Payment">
              <div class="btn btn-hover-effect btn-primary ">充值</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-4">
    <div class="card social-widget">
      <div class="card-body">
        <div style="margin: 20px;margin-bottom: 0px;">

        <div style="margin-bottom: 5px;font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;">
            <div>
              剩余字符额度
            </div>
            <div class="media " style="font-size: 32px;color: #51bb25;};position: absolute;font-weight: 800; right:35px;margin-top:-10px">
              <div class="col-sm-6 col-md-4 col-xl-3"
                  >
                <i  class="fa fa-folder-open"></i>
              </div>
            </div>
          </div>
          <div style="display: flex;margin:10px 0px;">
            <div style="line-height: 36px;font-size:25px;font-weight:500">
              {{ userinfo.translate_limit ?? 0 }}
            </div>
            <div>

              <div data-v-54df109c="" class="font-roboto" style="font-size: 12px; line-height: 36px; position: absolute; padding-left: 5px; color: rgba(43, 43, 43, 0.54);">
                <i data-v-54df109c="" class="el-tooltip el-icon-info" aria-describedby="el-tooltip-1044" tabindex="0" style="font-size: 16px;"></i>
              </div>

            </div>
          </div>
          <div style="font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;    justify-content: end;">
            <router-link to="/Pricing">

            <div class="btn btn-hover-effect btn-primary ">增加额度</div>
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-2 col-sm-4">
    <div class="card social-widget">
      <div class="card-body">
        <div style="margin: 20px;margin-bottom: 0px;">

        <div style="margin-bottom: 5px;font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;">
            <div>
             已用端口/全部端口
            </div>
            <div class="media " style="font-size: 32px;color: rgb(0, 123, 255);};position: absolute;font-weight: 800; right:35px;margin-top:-10px">
              <div class="col-sm-6 col-md-4 col-xl-3">
                <i  class="fa fa-comments"></i>
              </div>
            </div>
          </div>
          <div style="display: flex;margin:10px 0px;">
            <div style="line-height: 36px;font-size:25px;font-weight:500">
             {{userinfo.used_sessions_limit ??0}}/{{ userinfo.session_limit ?? 0}}
            </div>
            <div>

              <div data-v-54df109c="" class="font-roboto" style="font-size: 12px; line-height: 36px; position: absolute; padding-left: 5px; color: rgba(43, 43, 43, 0.54);">
                <i data-v-54df109c="" class="el-tooltip el-icon-info" aria-describedby="el-tooltip-1044" tabindex="0" style="font-size: 16px;"></i>
              </div>

            </div>
          </div>
          <div style="font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;    justify-content: end;">
            <router-link to="/Pricing">

            <div class="btn btn-hover-effect btn-primary ">购买/续费</div>
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div  class="col-xl-2 col-sm-4">
    <div class="card social-widget">
      <div class="card-body">
        <div style="margin: 20px;margin-bottom: 0px;">

        <div style="margin-bottom: 5px;font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;">
            <div>
              剩余助手token
            </div>
            <div class="media " style="font-size: 32px;color: #51bb25;};position: absolute;font-weight: 800; right:35px;margin-top:-10px">
              <div class="col-sm-6 col-md-4 col-xl-3">
                <i  class="fa fa-calendar"></i>
              </div>
            </div>
          </div>
          <div style="display: flex;margin:10px 0px;">
            <div style="line-height: 36px;font-size:25px;font-weight:500">
              {{userinfo.llm_limit ?? 0}}
            </div>
            <div>

              <div data-v-54df109c="" class="font-roboto" style="font-size: 12px; line-height: 36px; position: absolute; padding-left: 5px; color: rgba(43, 43, 43, 0.54);">
                <i data-v-54df109c="" class="el-tooltip el-icon-info" aria-describedby="el-tooltip-1044" tabindex="0" style="font-size: 16px;"></i>
              </div>

            </div>
          </div>
          <div style="font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;    justify-content: end;">
            <router-link to="/Pricing">
            <div class="btn btn-hover-effect btn-primary ">提升额度</div>
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-2 col-sm-4">
    <div class="card social-widget">
      <div class="card-body">
        <div style="margin: 20px;margin-bottom: 0px;">

        <div style="margin-bottom: 5px;font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;">
            <div>
              剩余有效日（端口）
            </div>
            <div class="media " style="font-size: 32px;color: #51bb25;};position: absolute;font-weight: 800; right:35px;margin-top:-10px">
              <div class="col-sm-6 col-md-4 col-xl-3">
                <i  class="fa fa-calendar"></i>
              </div>
            </div>
          </div>
          <div style="display: flex;margin:10px 0px;">
            <div style="line-height: 36px;font-size:25px;font-weight:500">
             <span v-if="userinfo.has_expired == false" >
                {{userinfo.expired_days ?? "-"}} 天
             </span>
              <span v-else style="color:red">
                已过期
              </span>
            </div>
            <div>

              <div data-v-54df109c="" class="font-roboto" style="font-size: 12px; line-height: 36px; position: absolute; padding-left: 5px; color: rgba(43, 43, 43, 0.54);">
                <i data-v-54df109c="" class="el-tooltip el-icon-info" aria-describedby="el-tooltip-1044" tabindex="0" style="font-size: 16px;"></i>
              </div>

            </div>
          </div>
          <div style="font-weight: 400; color: rgba(43, 43, 43, .54);display:flex;    justify-content: end;">
            <router-link to="/Pricing">

            <div class="btn btn-hover-effect btn-primary ">
                去续费
            </div>
            </router-link>

          </div>

        </div>
      </div>
    </div>
  </div>


</template>
<script>
import {socialDashboard} from '../../../data/comon'
import { toast } from 'vue3-toastify';
export default {
  props: {
    userinfo: {
      type: Array,
      required: true
    }
  },
    data() {
        return {
          socialDashboard: socialDashboard,
          // feather_icons:vue-feather
        }
    },
  created() {
      console.log(this.userinfo)
  },

}
</script>
<style lang="scss" scoped>
.purchase-btn {
  background: linear-gradient(90deg, var(--theme-deafult) -18.33%, #563ee0 62.78%);
  transition: .5s;
}
</style>