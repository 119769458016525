<template>
  <head>
    <title>同心出海</title>
  </head>
  <router-view></router-view>
</template>

<script>



export default {

  name: 'App',
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    '$route'() {
      setTimeout(() => {
        this.loading = !this.loading
      }, 1000)
      this.loading = !this.loading
    }
  },
  mounted() {
    this.loading = false;

  }
}
</script>

<style>
.el-dialog__headerbtn .el-dialog__close{
  display: block !important;
}
</style>

