<template>
  <div>
    <el-dialog v-model="visible" title="日志记录" width="900"    destroy-on-close>
      <div style="margin-left:30px">
        <el-table :data="tableData" empty-text="暂无数据" style="width: 100%">
          <el-table-column align="center" prop="created_at" label="时间"/>
          <el-table-column align="center" label="类型">
            <template #default="scope">
              <el-tag v-if="scope.row.type==1" type="success">登录</el-tag>
              <el-tag v-if="scope.row.type==2" type="warning">离线</el-tag>
              <el-tag v-if="scope.row.type==3" type="danger">退出</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="visible = false">
            关闭
          </el-button>
        </div>
      </template>

    </el-dialog>
  </div>
</template>
<script>

import { apiSubAccountSessionLog } from '@/api/sub_account_session'
export default {
  name: 'sessionLogDialog',
  data() {
    return {
      visible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      requestParams: {
        sub_account_session_id:""
      }
    };
  },
  mounted() {
  },

  methods: {
    openVisibleDialog(row) {
      this.visible = true;
      this.requestParams.sub_account_session_id =row.id
      this.fetchData()
    },
    fetchData() {
      this.tableData = []
      apiSubAccountSessionLog({
        page_size: this.pageSize,
        page: this.currentPage,
        sub_account_session_id:this.requestParams.sub_account_session_id
      }).then(response => {
        this.tableData = response
      })
    },
    handlePrevClick() {
    },
    handleNextClick() {
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    }
  },
}

</script>

<style scoped lang="scss">

</style>