// const pkg = require('../../package.json')
//
//
// export const Version = pkg.version

export function getRendererEnvConfig() {

    console.log('process.envsssss',process.env)
    return process.env
}

