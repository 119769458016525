<template>
  <div>
    <div style="display:flex">
      <div class="search">
        <div class="block large-margins">
          <el-date-picker
              v-model="beforetime"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY-MM-DD HH:mm:ss"
              date-format="MMM DD, YYYY"
              time-format="HH:mm"
          />
        </div>
        <div class="block large-margins" style="text-align: center;">
          <el-date-picker
              v-model="aftertime"
              type="datetime"
              placeholder="请选择结束日期"
              format="YYYY-MM-DD HH:mm:ss"
              date-format="MMM DD, YYYY"
              time-format="HH:mm"
              style=""
          />
        </div>



        <div class="block large-margins" style="text-align: center;">
          <el-select v-model="value" placeholder="请选择邀请码" style="width: 200px">
            <el-option
                v-for="item in status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
            />
          </el-select>
        </div>

        <div class="block large-margins" style="text-align: center;">
          <el-select v-model="value" placeholder="请选择账号用户名" style="width: 200px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
            />
          </el-select>
        </div>
        <div class="block large-margins" style="text-align: center;">
          <el-input v-model="input" style="width: 240px" placeholder="请输入联系人账号" />
        </div>
      </div>
      <!--        11111-->
      <div class="" style="text-align: center;display: flex;flex: 0.3 1 0%;  height: 36px; margin-top: 15px; margin-left: 5px;">
        <el-button type="primary" :icon="Search" class="search-but">搜索</el-button>
        <el-button type="warning" :icon="Search" class="search-but">重置</el-button>
      </div>
    </div>
    <div  class="large-margins">

    </div>
    <div class="large-margins">
      <el-table :data="tableData" border scrollbar-always-on
                style="width: 100%;margin-top:20px" :is-selected-all="isSelectedAll" @select="select"
                @select-all="selectAll"  ref="innerTable"
      >
        <el-table-column align="center" type="selection" width="70" />

        <el-table-column align="center" prop="address" label="邀请码" width="150"/>
        <el-table-column align="center" prop="address" label="账号用户名" width="182"/>
        <el-table-column align="center" prop="address" label="联系人账号" width="150"/>
        <el-table-column align="center" prop="address" label="创建日期" width="200"/>
        <el-table-column align="center" prop="address" label="内容" width="400"/>


        <!--        操作-->
        <el-table-column align="center" label="操作" fixed="right" width="200">
          <template #default="scope">
            <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-button
                size="small"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>
<script >


export default {
  data() {
    name:'followUp'
    return {
      dialogyqmGroupVisible: false,
      beforetimeL:'',
      aftertime:'',
      tableData:  [
        {
          date: '2016-05-04',
          name: 'Aleyna Kutzner',
          address: '1000',
          status:1,
        },
        {
          date: '2016-05-03',
          name: 'Helen Jacobi',
          address: '1000',
          status:0,
        },
        {
          date: '2016-05-02',
          name: 'Brandon Deckert',
          address: '1000',
          status:1,
        },
        {
          date: '2016-05-01',
          name: 'Margie Smith',
          address: '1000',
          status:0,
        },
      ],
      status:[
        {
          value: '1',
          label: '正常',
        },
        {
          value: '2',
          label: '禁用',
          disabled: true,
        },

      ],
    };

  },
  components:{


  },
  watch: {

  },


  methods:{
    chageselectAll(){
      this.$refs.innerTable.clearSelection();
    },
    ExportContent(){

    }
  }
}
</script>
<style scoped lang="scss">
.search{
  display:flex;
  flex-flow: wrap; justify-content: start;
  flex: 1.6 1 0%; min-width: 350px;
}
.large-margins{
  margin:15px 8px;
}
</style>