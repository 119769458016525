// 设置 setStorage
export const setStorage = (key, value) => {
    if (isJson(value)) {
        value = JSON.stringify(value)
    }

    //if has then del
    if (hasStorage(key)){
        removeStorage(key)
    }
    //is object
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    // set localStorage
    localStorage.setItem(key, value)
}

// 获取 getStorage
export const getStorage = (key) => {
    let value = null
    if (
        !localStorage.getItem(key) ||
        JSON.stringify(localStorage.getItem(key)) === 'null'
    ) {
        return null
    }
    if (isJson(localStorage.getItem(key))) {
        value = JSON.parse(localStorage.getItem(key))
    } else {
        value = localStorage.getItem(key)
    }
    return value
}

// 是否存在 hasStorage
export const hasStorage = (key) => {

}

// 获取所有key
export const getStorageKeys = () => {
    const items = getStorageAll()

    const keys = []
    for (let index = 0; index < items.length; index++) {
        keys.push(items[index].key)
    }
    return keys
}

// 根据索引获取key
export const getStorageForIndex = (index) => {
    return localStorage.key(index)
}

// 获取localStorage长度
export const getStorageLength = () => {
    return localStorage.length
}

// 获取全部 getAllStorage
export const getStorageAll = () => {
    const storageAll = {};
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
        storageAll[key] = JSON.parse(value);
    }
    return storageAll;
}

// 删除 removeStorage

export const removeStorage = (key) => {
    localStorage.removeItem(key)
}

// 清空 clearStorage
export const clearStorage = () => {
    localStorage.clear()
}

// 判断是否可用 JSON.parse
export const isJson = (value) => {
    if (Object.prototype.toString.call(value) === '[object String]') {
        try {
            const obj = JSON.parse(value)
            const objType = Object.prototype.toString.call(obj)
            return objType === '[object Object]' || objType === '[object Array]'
        } catch (e) {
            // console.log('error：' + value + '!!!' + e);
            return false
        }
    }
    return false
}

