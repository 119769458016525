
<template>
  <div class="card social-widget" style="min-height: 70vh;">
    <div class="card-body">
      <el-table :data="tableData"  empty-text="暂无数据" style="width: 100%">
        <el-table-column prop="order_id" label="订单号" />
        <el-table-column prop="amount" label="充值金额">
          <template #default="scope">
            <span>${{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="channel_name" label="充值渠道"/>
        <el-table-column prop="remark" label="详情"/>
        <el-table-column prop="created_at" label="创建时间"/>
        <el-table-column prop="status" label="订单状态">
          <template #default="scope">
            <el-tag :type="scope.row.status === 2?'success' : 'warning'">{{scope.row.status === 2? '已完成' : '未完成'}}</el-tag>
          </template>
        </el-table-column>
      </el-table>


      <template slot="#empty">
        <el-empty description="description"/>
      </template>

      <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"
                          layout="prev, pager, next"
                          :total="total"/>
      </span>
    </div>
  </div>

</template>

<script >
import {apiPaymentOrder} from "@/api/paymentOrder";

export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      apiPaymentOrder({
        page_size: this.pageSize,
        page:this.currentPage,
      }).then(response => {
        const {list, total } = response
        this.tableData = list
        this.total = total
      })
    },
    handlePrevClick() {},
    handleNextClick() {},
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    }
  }
}
</script>
