<template>
  <div class="card">
    <div class="container-fluid email-wrap bookmark-wrap todo-wrap card-body">
      <div class="container-fluid">
        <div class="page-title">
          <div class="row">
            <div class="col-2 d-flex">
              <h5>词条管理 / {{corpus_topic.corpus_topic_name}}</h5>
            </div>
            <div class="col-3">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-2 ">
          <div class="email-app-sidebar left-bookmark custom-scrollbar">
            <div class="p-0">
              <div class="row g-sm-4 g-2">
                <div class="col-xl-12 col-md-4" style="display: flex;justify-content: space-between;">
                  <el-button type="primary" @click="goback()" width="100%">返回上级</el-button>
                  <el-button type="success" @click="handelCreate" width="100%">创建词条</el-button>

                </div>

                <div class="col-xl-12 col-md-4" v-for="item in menu">
                  <div :class="currentMenu== item.type ? 'active' :''" class="light-card balance-card widget-hover"
                       @click="menuSelect(item)">
                    <div class="">
                      {{ item.name }}
                    </div>

                    <div>({{ item.count }})</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-10  box-col-12">
          <el-table :data="tableData" border ref="innerTable">

            <el-table-column align="center" prop="title" show-overflow-tooltip  label="问题索引"/>
            <el-table-column align="center" prop="content" show-overflow-tooltip label="内容预览"/>
            <el-table-column align="center" prop="status" label="状态">
              <template #default="scope">
                <el-tag v-if="scope.row.status == 1" type="success">正常</el-tag>
                <el-tag v-if="scope.row.status == 2" type="danger">已禁用</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="train_status" label="训练状态">
              <template #default="scope">
                <el-tag v-if="scope.row.train_status == 1" type="success">已训练</el-tag>
                <el-tag v-if="scope.row.train_status == 2" type="danger">未训练</el-tag>
                <el-tag v-if="scope.row.train_status == 0" type="info">待训练</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="description" show-overflow-tooltip  label="备注"/>
            <el-table-column align="center" prop="created_at" show-overflow-tooltip  label="创建时间"/>


            <!--        操作-->
            <el-table-column align="center" label="操作" fixed="right" width="260">
              <template #default="scope">
                <el-button :icon="Edit" size="small" type="primary" @click="handelUpdate(scope.row)">
                  编辑
                </el-button>

                <el-button :icon="HelpFilled" size="small" type="success" @click="handelTrain(scope.row)">
                  训练
                </el-button>
                <el-button :icon="Delete" size="small" type="danger" @click="handelDelete(scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <template slot="#empty">
            <el-empty description="description"/>
          </template>
          <span class="m-t-10" style="display: flex;justify-content: center;">
                     <el-pagination small background
                                    @prev-click="handlePrevClick"
                                    @next-click="handleNextClick"
                                    @current-change="handleCurrentChange"
                                    layout="total,prev, pager, next"
                                    :total="total"/>
                </span>
        </div>
      </div>
    </div>

  </div>
  <formDialog ref="formDialog"/>
</template>
<script>
import formDialog from "@/pages/Reply/corpus/formDialog.vue";
import {Delete, Edit} from "@element-plus/icons-vue";
import Position from "@/pages/uikits/helperClass/Position.vue";
import {apiCorpus, apiCorpusDelete, apiCorpusStatical, apiCorpusTrain} from "@/api/corpus";

export default {
  computed: {
    Edit() {
      return Edit
    },
    Position() {
      return Position
    },
    Delete() {
      return Delete
    }
  },
  components: {
    formDialog,

  },
  data() {
    return {
      corpus_topic:{},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [
      ],
      currentMenu: 1,
      menu: [
        {
          type: 1,
          name: "人物背景",
          count: 0,
        },
        {
          type: 2,
          name: "业务背景",
          count: 0,
        },
        {
          type: 3,
          name: "主观意见",
          count: 0,
        },
        {
          type: 4,
          name: "闲聊话术",
          count: 0,
        },

      ],
    };
  },
  created() {
    this.corpus_topic = this.$route.query
    this.fetchData()
  },
  methods: {
    goback(){
      this.$router.go(-1)
    },
    fetchData() {
      this.fetchStatical()
      apiCorpus({
        corpus_topic_id: parseInt(this.corpus_topic.id),
        corpus_type: this.currentMenu,
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    fetchStatical(){
      apiCorpusStatical({
        corpus_topic_id: parseInt(this.corpus_topic.id),
      }).then(res => {
        //res = [{"count":3,"corpus_type":1}]
        // if this.menu.type == res[index].type then this.menu.count = res[index].count
        res.forEach((item, index) => {
          // 如果 this.menu.type 等于 res[index].corpus_type
          //map filter
          this.menu.forEach((item, index) => {
            if (item.type === res[index].corpus_type) {
              this.menu[index].count = res[index].count
            }
          })
        });
      })
    },
    handelCreate() {
      this.$refs.formDialog.openVisibleDialog({})
    },
    handelUpdate(row) {
      this.$refs.formDialog.handleType = 'update'
      this.$refs.formDialog.openVisibleDialog(row)
    },
    handelDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiCorpusDelete({
          id: row.id
        }).then((r) => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.fetchData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handelTrain(row) {
      this.$confirm('此操作将进行训练词条，会消耗助手token, 是否继续?', '提示', {
        confirmButtonText: '开始生成',
        cancelButtonText: '取消',
      }).then(() => {
        apiCorpusTrain({
          id: row.id
        }).then((r) => {
          this.$message({
            type: 'success',
            message: '训练成功!'
          });
          this.fetchData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handlePrevClick() {
    },
    handleNextClick() {
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    menuSelect(item) {
      this.currentMenu = item.type
      this.fetchData()

    }
  }
}
</script>
<style scoped>

.page-wrapper .page-body-wrapper .page-title {
  padding: 15px 15px;
  margin: 0 -27px 30px;
  background-color: #fff;
  padding-bottom: 0px;
  margin-bottom: 15px;
  padding-top: 0px;
}

.todo-wrap.email-wrap .email-app-sidebar .main-menu > li a {
  padding: 0 0;
}

.active {
  font-weight: bold;
  color: #0A96E6;
  background: #16c7f917;
}

.balance-card {
  cursor: pointer;
}
</style>