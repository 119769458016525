<template>
<div class="col-xxl-3 col-md-6 box-col-6 col-ed-none wow zoomIn">
    <div class="card purchase-card">
        <img class="img-fluid" src="@/assets/images/dashboard/purchase.png" alt="vector mens with laptop" />
        <div class="card-body pt-3">
            <h6 class="mb-3">
                Buy <a href="#">Pro Account </a>to Explore Premium Features
            </h6>
            <a class="purchase-btn btn btn-primary btn-hover-effect f-w-500" href="https://1.envato.market/3GVzd" target="_blank">Purchase Now</a>
        </div>
    </div>
</div>
</template>
