const http = require('@/utils/http')

const Api = {
    CORPUS: "/corpus",
    CORPUS_CREATE: "/corpus/create",
    CORPUS_UPDATE: "/corpus/update",
    CORPUS_DELETE: "/corpus/delete",
    CORPUS_TRAIN: "/corpus/train",
    CORPUS_STATICAL: "/corpus/statical",
}

exports.Api = Api


exports.apiCorpus = (data) => {
    return http.post(Api.CORPUS, {data})
}


exports.apiCorpusCreate = (data) => {
    return http.post(Api.CORPUS_CREATE, {data})
}




exports.apiCorpusUpdate = (data) => {
    return http.post(Api.CORPUS_UPDATE, {data})
}


exports.apiCorpusDelete = (data) => {
    return http.post(Api.CORPUS_DELETE, {data})
}


exports.apiCorpusTrain = (data) => {
    return http.post(Api.CORPUS_TRAIN, {data})
}


exports.apiCorpusStatical = (data) => {
    return http.post(Api.CORPUS_STATICAL, {data})
}
