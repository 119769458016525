<script >
export default {
  data() {
    return {

      dialogCreateFormVisible: false,
      form:[
        {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
      ],
    };
  },
  components:{


  },
  created() {

    window.open('https://www.baidu.com');

  },
  watch: {

  },


  methods:{
    buy(){
      this.dialogCreateFormVisible=true
    },
    ClearRecords(index,row){
      // this.dialogyqmShareVisible=true
    },
    handleChange(){

    }
  }
}
</script>

<template>

</template>

<style scoped lang="scss">

</style>