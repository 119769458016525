<template>
  <div class="card social-widget">
    <div class="card-body">
      <div style="display:flex">
        <div class="search">
          <div class="block large-margins">
            <el-date-picker
                v-model="beforetime"
                type="datetime"
                placeholder="请选择开始日期"
                format="YYYY-MM-DD HH:mm:ss"
                date-format="MMM DD, YYYY"
                time-format="HH:mm"
            />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-date-picker
                v-model="aftertime"
                type="datetime"
                placeholder="请选择结束日期"
                format="YYYY-MM-DD HH:mm:ss"
                date-format="MMM DD, YYYY"
                time-format="HH:mm"
                style=""
            />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-input v-model="input" style="width: 240px" placeholder="请输入主域名" />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-input v-model="input" style="width: 240px" placeholder="请输入编码" />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-input v-model="input" style="width: 240px" placeholder="请输入注释" />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="value" placeholder="请选择拉链方式" style="width: 200px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
              />
            </el-select>
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="value" placeholder="请选择邀请码" style="width: 200px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
              />
            </el-select>
          </div>


        </div>
        <!--        11111-->
        <div class="" style="text-align: center;display: flex;flex: 0.3 1 0%;  height: 36px; margin-top: 15px; margin-left: 5px;">
          <el-button type="primary" :icon="Search" class="search-but">搜索</el-button>
          <el-button type="warning" :icon="Search" class="search-but">重置</el-button>
        </div>
      </div>
      <div  class="large-margins">
        <el-button type="primary"  @click="dialogFormVisible = true">创建邀请码</el-button>

      </div>
      <div class="large-margins">
        <el-table :data="tableData" border scrollbar-always-on show-summary sum-text="合计"
                  style="width: 100%;margin-top:20px" :is-selected-all="isSelectedAll" @select="select"
                  @select-all="selectAll"  ref="innerTable"
        >
          <el-table-column align="center" type="selection" width="70" />

          <el-table-column align="center" label="序号" width="180">
            <template #default="scope">
              <el-tag>{{ scope.row.name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" label="主域名" width="150"/>
          <el-table-column align="center" prop="address" label="完整短域" width="150"/>
          <el-table-column align="center" prop="address" label="取链方式" width="150"/>
          <el-table-column align="center" prop="address" label="邀请码" width="150"/>
          <el-table-column align="center" prop="address" label="跳转规则" width="150"/>
          <el-table-column align="center" prop="address" label="创建时间" width="150"/>

          <el-table-column align="center" prop="address" label="进粉设置" width="150"/>

          <el-table-column align="center" prop="address" label="注释" width="150"/>
          <el-table-column align="center" prop="address" label="消息文本" width="150"/>
          <el-table-column align="center" prop="address" label="点击量" width="150"/>
          <el-table-column align="center" prop="address" label="错误跳转" width="150"/>



          <!--        操作-->
          <el-table-column align="center" label="操作" fixed="right" width="250">
            <template #default="scope">
              <el-button size="small" type="primary" @click="handlelook(scope.$index, scope.row)" text>
                查看
              </el-button>
              <el-button text size="small" type="primary"
                         @click="handleShare(scope.$index, scope.row)">
                分享
              </el-button>
              <el-button text size="small" type="primary"
                         @click="handlelook(scope.$index, scope.row)" >
                编辑
              </el-button>
              <el-button text size="small" type="primary"
                         @click="dialogTableVisible = true" >
                禁用
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="large-margins" style="display:flex">
          <div>
            <el-button    @click="ClearRecords()">清除记录</el-button>
          </div>
        </div>

      </div>
    </div>
  </div>


  <el-dialog v-model="dialogFormVisible" title="创建分流链接" width="1200">
    <div style="margin-left:30px">
      <el-form :model="form" >

        <el-form-item label="选择域名">
          <el-radio-group v-model="form.resource">
            <el-radio value="1">www</el-radio>
            <el-radio value="2">www</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="创建方式" >
          <el-input v-model="form.name" style="width: 240px" placeholder="请输入备注信息"/>
        </el-form-item>
        <el-form-item label="提链方式">
          <el-radio-group v-model="form.resource">
            <el-tooltip
                class="box-item"
                effect="dark"
                content="将根据添加的顺序进行跳转访问"
                placement="top-start"
            >
              <el-radio value="1">顺序</el-radio>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="将根据随机一条进行跳转访问"
                placement="top-start"
            >
              <el-radio value="2">随机</el-radio>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="邀请码">
          <el-select v-model="form.region" placeholder="邀请码" style="width: 240px">
            <el-option label="11111" value="1" />
            <el-option label="22222" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="跳转规则">
          <el-radio-group v-model="form.resource">
            <el-radio value="1">指定平台</el-radio>
            <el-radio value="2">指定账号</el-radio>
            <el-radio value="2">其他</el-radio>
          </el-radio-group>
        </el-form-item>


        <div style="display: flex;">
          <el-form-item label="注释" >
            <el-input v-model="form.name" style="width: 240px" placeholder="请输入备注信息"/>
          </el-form-item>
          <el-form-item label="消息文本" style="margin-left:15px">
            <el-input v-model="form.name" style="width: 240px" placeholder="请输入分享密码"/>
          </el-form-item>

          <el-form-item label="错误跳转">
            <el-select v-model="form.region" placeholder="启用状态" style="width: 240px">
              <el-option label="正常" value="1" />
              <el-option label="禁用" value="2" />
            </el-select>
          </el-form-item>
        </div>

        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="Consistency" name="1">
            <div>
              Consistent with real life: in line with the process and logic of real
              life, and comply with languages and habits that the users are used to;
            </div>
            <div>
              Consistent within interface: all elements should be consistent, such
              as: design style, icons and texts, position of elements, etc.
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false">保存</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog>



  <!--禁用-->
  <el-dialog v-model="dialogTableVisible" title="是否确定停用邀请码" width="400">
    <div class="large-margins">
      <p>当邀请码被停用后：</p>
      <p>系统将回收已分配的会话数；</p>
      <p>客户端将关闭所有会话并退出；</p>
      <p>邀请码分享链接依旧可查看记录；</p>
      <p>将不再允许使用此邀请码。</p>
      <br />
      <div style="color: rgb(250, 173, 20);">
        停用后将进入系统回收倒计时，在15天后自动清空邀请码相关数据。
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false">确认</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>


import {apexDashboard} from "@/data/comon";
import { ref } from 'vue'

import { Delete, Edit, Search, Share, Upload } from '@element-plus/icons-vue'
import WelcomeCard from "@/pages/dashboard/default/WelcomeCard.vue";
export default {
  data() {
    return {
      beforetime:'',
      date:'',
      isSelectedAll:true,
      dialogyqmGroupVisible: false,
      dialogTableVisible:false,
      dialogFormVisible: false,
      dialogyqmShareVisible:false,
      aftertime:'',
      options:[
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
          disabled: true,
        },
        {
          value: 'Option3',
          label: 'Option3',
        },
        {
          value: 'Option4',
          label: 'Option4',
        },
        {
          value: 'Option5',
          label: 'Option5',
        },
      ],
      tableData:  [
        {
          date: '2016-05-04',
          name: 'Aleyna Kutzner',
          address: '1000',
          status:1,
        },
        {
          date: '2016-05-03',
          name: 'Helen Jacobi',
          address: '1000',
          status:0,
        },
        {
          date: '2016-05-02',
          name: 'Brandon Deckert',
          address: '1000',
          status:1,
        },
        {
          date: '2016-05-01',
          name: 'Margie Smith',
          address: '1000',
          status:0,
        },
      ],
      form:[
        {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
      ],
    };
  },
  components:{


  },
  watch: {

  },


  methods:{
    ClearRecords(index,row){
      // this.dialogyqmShareVisible=true
    },
    handleChange(){

    }
  }
}
</script>
<style scoped>
.large-margins{
  margin:15px 8px;
}
.search-but{
  width:120px
}
.el-input__wrapper {
  padding: 0 !important;
}
.el-input__prefix{
  padding-left:10px !important;
}
.search{
  display:flex;
  flex-flow: wrap; justify-content: start;
  flex: 1.6 1 0%; min-width: 350px;
}
.el-input--prefix .el-input__inner {
  padding-left: 30px;
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}
.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}
.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
}
.line {
  width: 1px;
  background-color: var(--el-border-color);
}
</style>