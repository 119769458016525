<template>
  <div class="card social-widget">
    <div class="card-body">
      <div style="display:flex">
        <div class="search">
          <div class="block large-margins">
            <el-date-picker
                v-model="form.start_time"
                type="datetime"
                placeholder="请选择开始日期"
                value-format="YYYY-MM-DD HH:mm:ss"

            />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-date-picker
                v-model="form.end_time"

                type="datetime"
                placeholder="请选择结束日期"
                value-format="YYYY-MM-DD HH:mm:ss"

            />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-input v-model="form.account_code" style="width: 240px" placeholder="请输入邀请码"/>
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-input v-model="form.remark" style="width: 240px" placeholder="请输入备注信息"/>
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="form.status" placeholder="启用状态" style="width: 240px">
              <el-option label="正常" :value="1"/>
              <el-option label="禁用" :value="2"/>
            </el-select>
          </div>

        </div>
        <div class=""
             style="text-align: center;display: flex;flex: 0.3 1 0%;  height: 36px; margin-top: 15px; margin-left: 5px;">
          <el-button type="primary" class="search-but" @click="fetchData">搜索</el-button>
          <el-button type="warning" class="search-but" @click="form={}">重置</el-button>
        </div>
      </div>
      <div class="large-margins">
        <el-button type="primary" @click="handleCreateSubAccount">创建邀请码</el-button>
      </div>
      <div class="large-margins">
        <el-table :data="tableData" border scrollbar-always-on
                  style="width: 100%;margin-top:20px"
                  ref="innerTable"
        >
          <el-table-column align="center" label="邀请码" width="180">
            <template #default="scope">
              <a href="javascript:void(0)" @click="handleCopy(scope.row)"
                 style="color: #409EFF;cursor: pointer;">{{ scope.row.account_code }}</a>
            </template>
          </el-table-column>
          <el-table-column align="center" label="消费类型" width="100">
            <template #default="scope">
              <el-tag v-if="scope.row.consume_type==1" type="success">端口</el-tag>
              <el-tag v-if="scope.row.consume_type==2" type="primary">字数</el-tag>

            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间/最近登录" width="200">
            <template #default="scope">
              <div>
                {{ scope.row.create_time }}
              </div>
              <div>
                {{ scope.row.last_login_time }}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" label="在线/总账号" width="200">
            <template #default="scope">

              {{ scope.row.session_online_count }}
              /
              {{ scope.row.session_count }}

            </template>
          </el-table-column>
          <el-table-column align="center" label="分配端口/占用" width="200">
            <template #default="scope">
              {{ scope.row.session_limit }}
              /
              {{ scope.row.used_session_count }}

            </template>
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注信息" width="150"/>
          <el-table-column align="center" prop="reset_time_at" label="置零时间" width="200"/>
          <el-table-column align="center" label="启用状态" width="180">
            <template #default="scope">
              <el-tag v-if="scope.row.status==1" type="success">正常</el-tag>
              <el-tag v-if="scope.row.status!=1" type="danger">禁用</el-tag>
            </template>
          </el-table-column>

          <el-table-column align="center" label="WhatsApp" width="150">
            <template #default="scope">
              {{ scope.row.application_platform_count.Whatsapp ?? 0 }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="line" width="150">
            <template #default="scope">
              {{ scope.row.application_platform_count.Line ?? 0 }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="Telegram" width="150">
            <template #default="scope">
              0
            </template>
          </el-table-column>
          <el-table-column align="center" label="Instagram" width="150">
            <template #default="scope">
              0
            </template>
          </el-table-column>
          <!--        操作-->
          <el-table-column align="center" label="操作" fixed="right" width="250">
            <template #default="scope">
              <el-button text size="small" type="primary" @click="handlelook(scope.$index, scope.row)">
                查看
              </el-button>
              <el-button text size="small" type="primary" @click="handleShare(scope.$index, scope.row)">
                分享
              </el-button>
              <el-button text size="small" type="primary" @click="handleUpdateSubAccount(scope.row)">
                编辑
              </el-button>
              <el-button v-if="scope.row.status==1" text size="small" type="primary" @click="handlesDisable(scope.row)">
                停用
              </el-button>
              <el-button v-if="scope.row.status==2" text size="small" type="danger" @click="handlesDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"

                          layout="total,prev, pager, next"
                          :total="total"/>
      </span>
    </div>
  </div>


  <!--禁用-->

  <createSubAccount ref="createSubAccountDialog"/>
  <updateSubAccount ref="updateSubAccountDialog"/>
  <disable ref="disableSubAccountDialog"/>
  <share ref="share"/>
</template>
<script>

import share from './share.vue'

//邀请码api
import {apiSubAccount, apiSubAccountDelete} from "@/api/sub_account";
import createSubAccount from "@/pages/subAccount/index/createSubAccount.vue";
import updateSubAccount from "@/pages/subAccount/index/updateSubAccount.vue";
import disable from "@/pages/subAccount/index/disable.vue";

export default {
  data() {
    return {
      beforetime: '',
      date: '',
      currentPage: 1,
      pageSize2: 10,
      total: 0,
      dialogStopVisible: false,
      aftertime: '',
      options: [
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
          disabled: true,
        },
        {
          value: 'Option3',
          label: 'Option3',
        },
        {
          value: 'Option4',
          label: 'Option4',
        },
        {
          value: 'Option5',
          label: 'Option5',
        },
      ],
      tableData: [],
      form: {},
      page: 1,
      page_size: 10
    };
  },
  components: {
    share,
    createSubAccount,
    updateSubAccount,
    disable
  },
  created() {
    this.fetchData()
  },

  methods: {
    refreshPage() {
      location.reload();
    },
    getList() {
      getInviteList({
        page: this.currentPage,
        page_size: this.pageSize2
      }) // Assuming code is password here
          .then(response => {
            this.tableData = response.list
            this.total = response.total
            // console.log(this.tableData)
          })
          .catch(error => {
            // Handle error
            // console.error('Login failed:', error);
          });
    },
    openGroup() {
      this.dialogyqmGroupVisible = true
    },
    handlelook(index, row) {
      console.log(row.id)
      console.log(666)

      this.$router.push({
        path: '/invitecode',
        query: {
          id: row.id,
          account_code: row.account_code
        }
      })
    },
    handleShare(index, row) {
      this.$refs.share.visible = true
      this.$refs.share.setAccountInfo({
        shareCode: row.account_code,
        sessionCount: row.session_count,
        password: row.code_password,
        remark: row.remark
      })
    },
    fetchData() {
      apiSubAccount({
        page_size: this.pageSize,
        page: this.currentPage,
        ...this.form
      }).then(response => {
        const {list, total} = response
        this.tableData = list
        this.total = total
      })
    },
    handlePrevClick() {
    },
    handleNextClick() {
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    handleCreateSubAccount() {
      this.$refs.createSubAccountDialog.openVisibleDialog()
    },
    handleUpdateSubAccount(row) {
      this.$refs.updateSubAccountDialog.openVisibleDialog(row)
    },
    handlesDisable(row) {
      this.$refs.disableSubAccountDialog.openVisibleDialog(row)
    },
    handlesDelete(row) {
      this.$confirm('确认删除此邀请码?', 'Title', {
        autofocus: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除
        apiSubAccountDelete({id: row.id}).then(response => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.fetchData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleCopy(row) {
      navigator.clipboard.writeText(row.account_code)
          .then(() => {
            this.$message({
              message: '已复制',
              type: 'success'
            });
          })
          .catch(err => {
            console.error('复制失败:', err);
          });
    }
  }
}
</script>
<style scoped>
.demo-pagination-block + .demo-pagination-block {
  margin-top: 10px;
}

.demo-pagination-block .demonstration {
  margin-bottom: 16px;
}

.large-margins {
  margin: 15px 8px;
}

.search-but {
  width: 120px
}

.el-input__wrapper {
  padding: 0 !important;
}

.el-input__prefix {
  padding-left: 10px !important;
}

.search {
  display: flex;
  flex-flow: wrap;
  justify-content: start;
  flex: 1.6 1 0%;
  min-width: 350px;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px;
}

.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}

.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}

.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
}

.line {
  width: 1px;
  background-color: var(--el-border-color);
}

.el-button--text {
  border-color: transparent;
  color: #1890ff;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}

.el-button--mini {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 3px;
}
</style>