<template>
  <el-dialog
      v-model="visible"
      title="修改登录密码"
      width="500"
      align-top
      :close-on-click-modal="false"
  >

    <el-form :model="form" label-width="120px">
      <el-form-item label="旧密码">
        <el-input v-model="form.old_password" type="password" placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input v-model="form.new_password" type="password" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码">
        <el-input v-model="form.confirm_password" type="password" placeholder="请再次输入新密码"></el-input>
      </el-form-item>
    </el-form>


    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="visible = false">取消</el-button>
        <el-button size="small" type="primary" @click="save">确认</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script >
import {updatePassword} from "@/api/account";

export default {
  data() {
    return {
      visible: false,
      form:{
        old_password: '',
        new_password: '',
        confirm_password: ''
      }
    }
  },
  methods: {
    save() {
      // 验证新旧密码是否相同
      if (this.form.new_password === this.form.old_password) {
        this.$message.error('新旧密码不能相同');
        return;
      }

      // 验证两次输入的新密码是否相同
      if (this.form.new_password!== this.form.confirm_password) {
        this.$message.error('两次输入的新密码不一致');
        return;
      }
      updatePassword({
        ...this.form
      }).then((r) => {
        // 假设 API 调用成功，关闭对话框
        this.$message.success('修改密码成功');
        this.visible = false;
      })


    }
  }
}
</script>
<style scoped lang="scss">

</style>