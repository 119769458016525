<template>
  <el-dialog v-model="visible" title="语料库管理" width="600">
    <el-form :model="form" label-width="auto" style="max-width: 500px">
      <el-form-item label="名称" width="320">
        <el-input v-model="form.topic_name" placeholder=""/>
      </el-form-item>
      <el-form-item label="备注" width="320">
        <el-input v-model="form.description" placeholder=""/>
      </el-form-item>

      <el-form-item label="语言">
        <el-select v-model="form.language" placeholder="请选择">
          <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="save">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import {apiCorpusTopicCreate, apiCorpusTopicUpdate} from "@/api/corpus_topic";

export default {
  data() {
    return {
      visible: false,
      dataItem: {
        id: 0
      },
      form: {
        topic_name: "",
        description: "",
        status: 1,
        language: "EN"
      },
      status: [
        {value: 1, label: '正常'},
        {value: 2, label: '已禁用'}
      ],
      languages: [
        {value: 'ZH', label: '中文'},
        {value: 'EN', label: '英语'},
        {value: 'JA', label: '日语'},
        {value: 'KO', label: '韩语'},
        {value: 'BG', label: '保加利亚语'},
        {value: 'CS', label: '捷克语'},
        {value: 'DA', label: '丹麦语'},
        {value: 'DE', label: '德语'},
        {value: 'EL', label: '希腊语'},
        {value: 'ES', label: '西班牙语'},
        {value: 'ET', label: '爱沙尼亚语'},
        {value: 'FI', label: '芬兰语'},
        {value: 'FR', label: '法语'},
        {value: 'HU', label: '匈牙利语'},
        {value: 'ID', label: '印度尼西亚语'},
        {value: 'IT', label: '意大利语'},
        {value: 'LT', label: '立陶宛语'},
        {value: 'LV', label: '拉脱维亚语'},
        {value: 'NB', label: '挪威语'},
        {value: 'NL', label: '荷兰语'},
        {value: 'PL', label: '波兰语'},
        {value: 'BR', label: '葡萄牙语（巴西）'},
        {value: 'RO', label: '罗马尼亚语'},
        {value: 'RU', label: '俄语'},
        {value: 'SK', label: '斯洛伐克语'},
        {value: 'SL', label: '斯洛文尼亚语'},
        {value: 'SV', label: '瑞典语'},
        {value: 'TR', label: '土耳其语'},
        {value: 'UK', label: '乌克兰语'},
      ]
    }
  },
  methods: {
    openVisibleDialog(data) {
      this.visible = true;
      console.log('data',data.id)
      if (data && data.id !=undefined) {
        this.form = data;
      }
    },
    save() {
      console.log('form',this.form)
      if(this.form.id){
        apiCorpusTopicUpdate(this.form).then(res => {
          this.$message.success('修改成功');
          this.visible = false;
          this.$parent.fetchData();
          this.form ={
            topic_name: "",
            description: "",
            status: 1,
            language: "EN"
          }
        })
      }else{
        apiCorpusTopicCreate(this.form).then(res => {
          this.$message.success('添加成功');
          this.visible = false;
          this.$parent.fetchData();
          this.form = {
            topic_name: "",
            description: "",
            status: 1,
            language: "EN"
          }
        })
      }

    },
  },
}
</script>

<style scoped lang="scss">

</style>