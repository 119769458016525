const http = require('@/utils/http')

const Api = {
    GETACCOUNT: '/account/baseInfo',
    FANS:'/dashboard/contact',
    APPLICATION:'/dashboard/application',//APPLICATION
    UPDATE_PASSWORD:'/account/updatePassword',//UPDATE_PASSWORD
}

exports.Api = Api


exports.getAccount = (data) => {
    return http.post(Api.GETACCOUNT, { data })
}
/**
 * 粉丝统计
 * @param {Object} data
 * @param {string} data.code
 */
exports.getFans = (data) => {
    return http.post(Api.FANS, {  })
}
/**
 * app统计
 * @param {Object} data
 * @param {string} data.code
 */
exports.getApplication = (data) => {
    return http.post(Api.APPLICATION, {  })
}


/**
 * 重置密码
 * @param {Object} data
 * @param {string} data.code
 **/
exports.updatePassword = (data) => {
    return http.post(Api.UPDATE_PASSWORD, { data })
}


