
<template>
  <div class="card social-widget" style="min-height: 70vh;">
    <div class="card-body">
      <el-table :data="tableData"  empty-text="暂无数据" style="width: 100%">
        <el-table-column prop="id" label="ID" width="80"/>
        <el-table-column prop="created_at" label="登录时间" />
        <el-table-column prop="ip" label="登录IP"/>
        <el-table-column prop="country" label="国家"/>
        <el-table-column prop="system" label="系统"/>
        <el-table-column prop="version" label="版本"/>
        <el-table-column prop="browser" label="浏览器"/>
        <el-table-column prop="brow_core" label="内核"/>
      </el-table>

      <template slot="#empty">
        <el-empty description="description"/>
      </template>

      <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"
                          layout="prev, pager, next"
                          :total="total"/>
      </span>
    </div>
  </div>

</template>

<script >
  import {getLoginLog} from "@/api/loginLog";

  export default {
    data() {
      return {
        tableData: [],
        currentPage: 1,
        pageSize: 10,
        total: 0
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      fetchData() {
        getLoginLog({
          page_size: this.pageSize,
          page:this.currentPage,
        }).then(response => {
          const {list, total } = response
          this.tableData = list
          this.total = total
        })
      },
      handlePrevClick() {},
      handleNextClick() {},
      handleCurrentChange(page) {
        this.currentPage = page
        this.fetchData()
      }
    }
  }
</script>
