const http = require('@/utils/http')

const Api = {
    COMBOS_ORDER  :"/combo_order",
    COMBOS_ORDER_TRANSLATE  :"/combo_order/translate",
    COMBOS_ORDER_Llm  :"/combo_order/llm",
    COMBOS_ORDER_SESSION  :"/combo_order/session",
}

exports.Api = Api


exports.apiCombosOrder = (data) => {
    return http.post(Api.COMBOS_ORDER, { data })
}


exports.apiCombosOrderTranslate = (data) => {
    return http.post(Api.COMBOS_ORDER_TRANSLATE, { data })
}


exports.apiCombosOrderLlm = (data) => {
    return http.post(Api.COMBOS_ORDER_Llm, { data })
}


exports.apiCombosOrderSession = (data) => {
    return http.post(Api.COMBOS_ORDER_SESSION, { data })
}


