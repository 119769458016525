<template>
  <div class="card social-widget">
    <div class="card-body">
      <div style="display:flex">
        <el-button type="primary" @click="handelCorpusTopicDialog">创建语料库</el-button>

      </div>

      <div class="large-margins">
        <el-table :data="tableData" border style="width: 100%;margin-top:20px" ref="innerTable">

          <el-table-column align="center" prop="topic_name" label="名称"/>
          <el-table-column align="center" prop="address" label="已训练/词条数">
            <template #default="scope">
              {{ scope.row.corpus_trained_count }} / {{ scope.row.corpus_count }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="language" label="语言">
            <template #default="scope">
              {{ this.getLanguage(scope.row.language) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态">
            <template #default="scope">
              <el-tag v-if="scope.row.status === 1">
                正常
              </el-tag>
              <el-tag v-if="scope.row.status === 2" type="danger">
                已禁用
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="description" label="备注"/>


          <!--        操作-->
          <el-table-column align="center" label="操作" fixed="right" width="350">
            <template #default="scope">
              <el-button size="small" type="warning" @click="handelCorpus(scope.row)">
                管理词条
              </el-button>
              <el-button size="small" type="primary"
                         @click="handelCorpusTopicDialog(scope.row)">
                编辑
              </el-button>
              <el-button size="small" type="success"
                         @click="handelTrainCorpusTopic(scope.row)">
                语料训练
              </el-button>
              <el-button size="small" type="danger"
                         @click="handelDelete(scope.row)">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <span class="m-t-10" style="display: flex;justify-content: center;">
                     <el-pagination small background
                                    @prev-click="handlePrevClick"
                                    @next-click="handleNextClick"
                                    @current-change="handleCurrentChange"
                                    layout="total,prev, pager, next"
                                    :total="total"/>
                </span>

      </div>
    </div>
  </div>

  <handelCorpusTopicDialog ref="handelCorpusTopicDialog"/>
  <handelCorpusTrainDialog ref="handelCorpusTrainDialog"/>
</template>
<script>
import handelCorpusTopicDialog from "@/pages/Reply/handelCorpusTopicDialog.vue";
import handelCorpusTrainDialog from "@/pages/Reply/handelCorpusTrainDialog.vue";
import {apiCorpusTopic, apiCorpusTopicDelete} from "@/api/corpus_topic";

export default {
  components: {
    handelCorpusTopicDialog,
    handelCorpusTrainDialog,

  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      languages: [
        {value: 'ZH', label: '中文'},
        {value: 'EN', label: '英语'},
        {value: 'JA', label: '日语'},
        {value: 'KO', label: '韩语'},
        {value: 'BG', label: '保加利亚语'},
        {value: 'CS', label: '捷克语'},
        {value: 'DA', label: '丹麦语'},
        {value: 'DE', label: '德语'},
        {value: 'EL', label: '希腊语'},
        {value: 'ES', label: '西班牙语'},
        {value: 'ET', label: '爱沙尼亚语'},
        {value: 'FI', label: '芬兰语'},
        {value: 'FR', label: '法语'},
        {value: 'HU', label: '匈牙利语'},
        {value: 'ID', label: '印度尼西亚语'},
        {value: 'IT', label: '意大利语'},
        {value: 'LT', label: '立陶宛语'},
        {value: 'LV', label: '拉脱维亚语'},
        {value: 'NB', label: '挪威语'},
        {value: 'NL', label: '荷兰语'},
        {value: 'PL', label: '波兰语'},
        {value: 'BR', label: '葡萄牙语（巴西）'},
        {value: 'RO', label: '罗马尼亚语'},
        {value: 'RU', label: '俄语'},
        {value: 'SK', label: '斯洛伐克语'},
        {value: 'SL', label: '斯洛文尼亚语'},
        {value: 'SV', label: '瑞典语'},
        {value: 'TR', label: '土耳其语'},
        {value: 'UK', label: '乌克兰语'},
      ]

    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      apiCorpusTopic({
        page_size: this.pageSize,
        page: this.currentPage,
      }).then(res => {

        this.tableData = []
        this.tableData = res.list
        this.total = res.total
      })
    },
    handelCorpusTopicDialog(row) {
      this.$refs.handelCorpusTopicDialog.openVisibleDialog(row)
    },
    handelCorpus(row) {
      console.log('row',row)
      this.$router.push({path: '/Reply/corpus', query: {id: row.id, corpus_topic_name: row.topic_name}});
    },

    handelTrainCorpusTopic(row) {
      this.$refs.handelCorpusTrainDialog.openVisibleDialog(row)
    },
    handlePrevClick() {
    },
    handleNextClick() {
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    // input en , zh return 英语 中文 from languages
    getLanguage(language) {
      return this.languages.find(item => item.value === language).label
    },
    handelDelete(row) {
      this.$confirm('确定删除该语料库吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiCorpusTopicDelete({
          id: row.id,
        }).then(() => {
          this.fetchData()
          this.$message.success('删除成功')
        })
      })
    }
  }
}
</script>
<style scoped>
.large-margins {
  margin: 15px 8px;
}
</style>