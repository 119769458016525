const http = require('@/utils/http')

const Api = {
    REGISTER: '/public/register',
    CAPTCHA: '/public/captcha',
}

exports.Api = Api


exports.apiRegister = (data) => {
    return http.post(Api.REGISTER, {data})
}


exports.apiCaptcha = () => {
    return http.post(Api.CAPTCHA, {})
}


