const http = require('@/utils/http')

const Api = {
    SUB_ACCOUNT: '/sub_account',//invite  Details
    SUB_ACCOUNT_DELETE: '/sub_account/delete',
    SUB_ACCOUNT_CREATE: '/sub_account/create',
    SUB_ACCOUNT_UPDATE: '/sub_account/update',
}

exports.Api = Api


exports.apiSubAccount = (data) => {
    return http.post(Api.SUB_ACCOUNT, {data})
}


exports.apiSubAccountDelete = (data) => {
    return http.post(Api.SUB_ACCOUNT_DELETE, {data})
}


exports.apiSubAccountCreate = (data) => {
    return http.post(Api.SUB_ACCOUNT_CREATE, {data})
}


exports.apiSubAccountUpdate = (data) => {
    return http.post(Api.SUB_ACCOUNT_UPDATE, {data})
}
