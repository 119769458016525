const http = require('@/utils/http')

const Api = {
    LOGIN_LOG  :"/account_login_log"
}

exports.Api = Api


exports.getLoginLog = (data) => {
    return http.post(Api.LOGIN_LOG, { data })
}
