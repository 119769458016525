const http = require('@/utils/http')

const Api = {
    SUB_ACCOUNT_SESSION: '/session',
    SUB_ACCOUNT_SESSION_REMOVE: '/session/remove',
    SUB_ACCOUNT_SESSION_CONTACT: '/sub_account_session_contact',
    SUB_ACCOUNT_SESSION_LOG:'/sub_account_session_log',
}

exports.Api = Api


exports.apiSubAccountSessionContact = (data) => {
    return http.post(Api.SUB_ACCOUNT_SESSION_CONTACT, { data })
}
/**
 * 粉丝日志信息
 * @param {Object} data
 * @param {string} data.code
 */
exports.apiSubAccountSessionLog = (data) => {
    return http.post(Api.SUB_ACCOUNT_SESSION_LOG, { data })
}


exports.apiSubAccountSession = (data) => {
    return http.post(Api.SUB_ACCOUNT_SESSION, {data})
}


exports.apiSubAccountSessionRemove = (data) => {
    return http.post(Api.SUB_ACCOUNT_SESSION_REMOVE, {data})
}



