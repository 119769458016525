<template>
  <div>

    <div class="container-fluid">
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card" style="flex-direction: column;">
            <div>
              <div>
                <a class="logo">
                  <div style="display: flex;justify-content: center;align-items: center;padding: 9px;">
                    <img class="img-fluid for-light" src="@/assets/images/logo/logo-icon.png" alt="looginpage"
                         style="width: 40px;">
                    <div style="
    display: flex;
    flex-direction: column; /* 垂直排列子元素 */
    align-items: flex-start; /* 左对齐 */
    justify-content: space-between;
    align-content: space-between;
    margin-left: 6px;
    background-image: linear-gradient(90deg, #00c4cc, #7d71ff, #6952fa);
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    -webkit-background-clip: text;
    letter-spacing: 0.5px;

"><span style="
    font-size: 18px;
    font-weight: bold;
    color: #54ba4a;
        letter-spacing: 3.5px;
"> 同心出海 </span><span style="
    display: flex;
    font-weight: 400;
    letter-spacing: 3.5px;
"> SCRM跨境聚合应用平台 </span></div>
                  </div>

                </a>
              </div>
              <!--11-->
              <div class="login-main">
                <form class="theme-form">
                  <h4>{{ $t('Account login') }}</h4>
                  <p>{{ $t('Enter your email & password to login') }}</p>
                  <div class="form-group">
                    <label class="col-form-label">{{ $t('Email Address') }}</label>
                    <input class="form-control" type="email" required="" placeholder="请输入你的账号"
                           v-model="user.email.value">
                    <span class="validate-error" v-if="!user.email.value || !validEmail(user.email.value)">{{
                        user.email.errormsg
                      }}</span>

                  </div>
                  <div class="form-group">

                    <label class="col-form-label">密码</label>
                    <div class="form-input position-relative">
                      <input class="form-control btn-square" v-model="user.password.value" type="password"
                             placeholder="Password">
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" checked>
                      <label class="text-muted" for="checkbox1">{{ $t('Remember password') }}</label>
                    </div>
                    <div class="checkbox p-0">
                      <input id="checkbox2" type="checkbox" checked>
                      <label class="text-muted"  for="checkbox2">{{ $t('I have read and agree') }}</label>
                      <a @click="showDialog()" class="text-blue">{{ $t('System Service Platform Agreement') }}</a>
                      <el-dialog
                          v-model="centerDialogVisible"
                          :title="$t('Platform Agreement')"
                          width="500"
                          align-center
                          :show-close="fales"
                      >
                        <span> </span>
                        <template #footer>
                          <div class="dialog-footer">
                            <!--                                <el-button @click="centerDialogVisible = false">Cancel</el-button>-->
                            <el-button type="primary" @click="centerDialogVisible = false">
                              {{ $t('Confirm') }}
                            </el-button>
                          </div>
                        </template>
                      </el-dialog>
                    </div>
                    <div class="text-end mt-3">
                      <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="login">
                        {{ $t('Sign in') }}
                      </button>

                    </div>
                  </div>

                  <p class="mt-4 mb-0 text-center">没有账户?
                    <router-link class="ms-2" tag="a" to="/auth/register">
                      创建账户
                    </router-link>
                    <span class="large-btn"></span>
                  </p>

                </form>

              </div>

            </div>
            <div
                style="margin-top: 30px; width: 100%; bottom: 50px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
              <div>
                同心出海系列产品归属权为新加坡（TongXin）所有
              </div>
              <div>注意:新加坡TongXin旗下全系列产品，仅支持新币或美元付款，请认准官方客服，谨防上当受骗！</div>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import Language from "@/components/language.vue";
import {apiLoginCode} from '@/api/login.js';
import {setToken} from "@/utils/cache/localStorage";
import {setStorage} from "@/utils/cache/storage";

export default {
  name: 'login',
  components: {Language},
  data() {
    return {
      centerDialogVisible: false,

      result: {email: '', password: ''},
      activeName: 'first',
      value: {id: 'cn', name: '简体中文', icon: 'flag-icon-cn'},
      languages: '简体中文',
      ip: '',
      user: {
        email: {
          value: '',
          errormsg: ''
        },
        password: {
          value: '',
          errormsg: ''
        },
        code: {
          value: "请输入你的邀请码",
          errormsg: ''
        }
      }
    };
  },

  created() {
    //设置currentLanguage为cn
    localStorage.setItem('currentLanguage', 'cn');
    localStorage.setItem('currentLanguageIcon', 'flag-icon-cn');
    this.$i18n.locale = this.languages
    this.$store.dispatch('setLang', this.value);

    // this.changeLocale(value, language);
  },

  methods: {


    showpassword() {


    },
    showDialog() {
      this.centerDialogVisible = true
    },

    login() {
      if (!this.user.password.value || this.user.password.value.length < 7) {
        this.user.password.errormsg = 'min length 7'
      } else {
        this.user.password.errormsg = ''
      }

      if (!this.user.email.value) {
        this.user.email.errormsg = 'empty not allowed'
      } else if (!this.validEmail(this.user.email.value)) {
        this.user.email.errormsg = 'Valid email required.'
      } else {
        this.user.email.errormsg = ''
      }
      const userAgent = navigator.userAgent;

      const browserInfo = this.getBrowser(userAgent);

      apiLoginCode({
        username: this.user.email.value,
        password: this.user.password.value,
        system: this.getOS(userAgent),
        version: '1.0.0',
        browser: browserInfo.name,
        brow_core:this.getBrowserCore(userAgent)
        //获取本机ip
      }).then(response => {
            // Handle response

            localStorage.setItem("username", response.user_info.username)
            localStorage.setItem('User', JSON.stringify(response))
            setToken(response.token)
            this.logging = true
            this.$router.push('/')
            console.log('Login successful:', response.token);
          })
          .catch(error => {
            // Handle error
            console.error('Login failed:', error);
          });
    },
    validEmail: function (email) {
      return  true
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    getBrowserInfo() {
      const userAgent = navigator.userAgent;

      // 获取操作系统
      this.system = this.getOS(userAgent);
      // 获取浏览器和版本
      const browserInfo = this.getBrowser(userAgent);
      this.browser = browserInfo.name;
      this.version = browserInfo.version;
      // 获取浏览器内核
      this.browCore = this.getBrowserCore(userAgent);
    },
    getOS(userAgent) {
      if (userAgent.indexOf("Win") !== -1) return "Windows";
      if (userAgent.indexOf("Mac") !== -1) return "MacOS";
      if (userAgent.indexOf("X11") !== -1 || userAgent.indexOf("Linux") !== -1) return "Linux";
      if (userAgent.indexOf("Android") !== -1) return "Android";
      if (userAgent.indexOf("like Mac") !== -1) return "iOS";
      return "Unknown OS";
    },
    getBrowser(userAgent) {
      let browserName = "Unknown Browser";
      let version = "Unknown Version";

      if (userAgent.indexOf("Chrome") > -1) {
        browserName = "Chrome";
        version = userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/)[1];
      } else if (userAgent.indexOf("Firefox") > -1) {
        browserName = "Firefox";
        version = userAgent.match(/Firefox\/(\d+\.\d+)/)[1];
      } else if (userAgent.indexOf("Safari") > -1) {
        browserName = "Safari";
        version = userAgent.match(/Version\/(\d+\.\d+\.\d+)/)[1];
      } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
        browserName = "Internet Explorer";
        version = userAgent.match(/(?:MSIE |rv:)(\d+\.\d+)/)[1];
      }

      return {name: browserName, version: version};
    },
    getBrowserCore(userAgent) {
      if (userAgent.indexOf("Chrome") > -1) return "Blink";
      if (userAgent.indexOf("Firefox") > -1) return "Gecko";
      if (userAgent.indexOf("Safari") > -1) return "WebKit";
      if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) return "Trident";
      return "Unknown Core";
    }
  },
};
</script>
<style scoped>
.loginicon {
  display: block;
  width: 20px;
  margin: 3px auto;
}

.loginicon1 {
  display: block;
  width: 20px;
  height: 20px;
  margin: 3px auto;

}

.custom-tabs-label {
  margin: 3px 5px;
  padding-top: 10px;
}

.login-card .login-main .theme-form input {
  height: 40px;
}

.el-input__wrapper {
  padding: 0px;

}

.text-blue {
  color: #007bff !important;
  font-size: 14px;
  margin-left: 8px;
  display: -webkit-inline-box;
}

.el-tabs--left .el-tabs__header.is-left {
  margin-right: 0 !important;

}

.el-tabs__item {
  padding: 5px 3px !important;
  height: 80px;
  margin-top: 8px;
}

.el-tabs__item.is-active, .el-tabs__item {
  color: #0d6efd;
  background-color: #fff
}

.el-tabs__content {
  background-color: #fff
}

.el-tabs__item.is-active, .el-tabs__item:active {
  background-color: rgba(235, 237, 255, .71);
  border-right: 2px solid var(--theme-deafult);
  border-bottom: none;
  border-radius: 0;
  height: 50px;
  justify-content: center !important;
  color: #0972f3 !important;
  border-right: 2px solid #0972f3;
}

.el-tabs__content {
  background-color: #fff
}

.demo-tabs > .el-tabs__content {


  font-size: 32px;
  font-weight: 600;
}

.large-btn {
  margin-left: 10px
}

.translate_wrapper {
  display: inline-flex;
}

.el-dialog__headerbtn {
  display: none;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}
</style>