const http = require('@/utils/http')

const Api = {
    PAYMENT_ORDER :"/payment/list",
    PAYMENT_ORDER_CREATE :"/payment/create",
}

exports.Api = Api


exports.apiPaymentOrder = (data) => {
    return http.post(Api.PAYMENT_ORDER, { data })
}


exports.apiPaymentOrderCreate = (data) => {
    return http.post(Api.PAYMENT_ORDER_CREATE, { data })
}
