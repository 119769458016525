const http = require('@/utils/http')

const Api = {
    LOGIN_CODE: '/public/login',
    LOGOUT_CODE: '/public/logout'
}

exports.Api = Api

/**
 * 激活码登录
 * @param {{brow_core: string, country: string, password: string, system: string, ip: string, browser: string, version: string, username: string}} data
 * @param {string} data.code
 */
exports.apiLoginCode = (data) => {
    return http.post(Api.LOGIN_CODE, { data })
}

/**
 * 激活码登出
 */
exports.apiLogOutCode = () => {
    return http.post(Api.LOGOUT_CODE, {})
}


