<template>
  <div class="card social-widget">
    <div class="card-body">
      <div style="display:flex">
        <div class="search">
          <div class="block large-margins">
            <el-date-picker
                v-model="form.start_time"
                type="datetime"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss"
                placeholder="请选择开始日期"
                style="width: 150px"

            />
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-date-picker
                v-model="form.end_time"
                type="datetime"
                placeholder="请选择结束日期"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss"
                style="width: 150px"

            />
          </div>


          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="form.app_platform_id" placeholder="选择APP平台" style="width: 150px">
              <el-option
                  v-for="item in platform_code"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
              />
            </el-select>
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="form.sub_account_id" placeholder="选择邀请码" style="width: 180px">
              <el-option
                  v-for="item in subAccountList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
              />
            </el-select>
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-select v-model="form.sub_account_session_id" placeholder="选择账号" style="width: 180px">
              <el-option
                  v-for="item in subSessionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
              />
            </el-select>
          </div>
          <div class="block large-margins" style="text-align: center;">
            <el-input v-model="form.username" placeholder="请输入用户名" style="width: 150px"/>
          </div>
        </div>
        <div class=""
             style="text-align: center;display: flex;flex: 0.3 1 0%;  height: 36px; margin-top: 15px; margin-left: 5px;">
          <el-button type="primary" class="search-but" @click="searchInfo()">搜索</el-button>
          <el-button type="warning" class="search-but" @click="form={}">重置</el-button>
        </div>
      </div>

      <div class="large-margins">
        <el-table :data="tableData" border scrollbar-always-on style="width: 100%;margin-top:20px" ref="innerTable">
          <el-table-column align="center" label="APP" width="180">
            <template #default="scope">
              <el-tag v-if="scope.row.platform_code==1" type="success">Whatsapp</el-tag>
              <el-tag v-if="scope.row.platform_code==2" type="primary">Telegram</el-tag>
              <el-tag v-if="scope.row.platform_code==3" type="success">Line</el-tag>
              <el-tag v-if="scope.row.platform_code==4" type="primary">Facebook</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="username" show-overflow-tooltip label="用户名"/>
          <el-table-column align="center" prop="nickname" show-overflow-tooltip label="昵称"/>

          <el-table-column align="center" prop="avatar" label="头像" width="120">
            <template #default="scope">
              <el-avatar
                  v-if="scope.row.avatar!==''"
                  :src="scope.row.avatar"
              />
              <img class="img-30" v-if="scope.row.avatar===''" src="@/assets/images/user/user.png"/>

            </template>
          </el-table-column>

          <el-table-column align="center" prop="created_at" label="创建时间" width="180"/>

        </el-table>
        <template slot="#empty">
          <el-empty description="description"/>
        </template>

        <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"
                          layout="total,prev, pager, next"
                          :total="total"/>
        </span>
      </div>
    </div>
  </div>


</template>
<script>
import followUp from './followUp.vue'
import 'dayjs/locale/zh-cn'

import {apiSubAccountSession, apiSubAccountSessionContact} from "@/api/sub_account_session";
import {apiSubAccount} from "@/api/sub_account";

export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      form: {},
      status: [
        {
          value: 1,
          label: '正常',
        },
        {
          value: 2,
          label: '禁用',
        },

      ],
      is_repeat: [
        {
          value: true,
          label: '正常',
        },
        {
          value: false,
          label: '禁用',
        },

      ],
      subSessionList: [],
      platform_code: [
        {
          value: 1,
          label: 'Whatsapp',
        },
        {
          value: 3,
          label: 'Line',
        },
        {
          value: 2,
          label: 'Telegram',
        },
        {
          value: 4,
          label: 'Facebook',
        }
      ],
      subAccountList:[]
    };
  },
  components: {
    followUp

  },
  created() {
    this.fetchSubAccountSessionList()
    this.fetchSubAccountList()
    this.fetchData()
  },


  methods: {
    searchInfo() {
      this.fetchData()
    },
    fetchData() {
      apiSubAccountSessionContact({
        sub_account_session_id: -1,
        page_size: this.pageSize,
        page: this.currentPage,
        ...this.form
      }).then(response => {
        const {list, total} = response
        this.tableData = list
        this.total = total
      })
    },
    handlePrevClick() {
    },
    handleNextClick() {
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    fetchSubAccountSessionList() {
      apiSubAccountSession({
        sub_account_id: -1,
      }).then(r => {
        this.subSessionList = r.list.map(item => ({
          label: item.session_name,
          value: item.id,
        }))
      })
    },
    fetchSubAccountList() {
      apiSubAccount({
        page_size: 50,
        page:1,
      }).then(r => {
        this.subAccountList = r.list.map(item => ({
          label: item.account_code,
          value: item.id,
        }))
      })
    }

  }
}
</script>
<style scoped>
.large-margins {
  margin: 15px 8px;
}

.search-but {
  width: 120px
}

.el-input__wrapper {
  padding: 0 !important;
}

.el-input__prefix {
  padding-left: 10px !important;
}

.search {
  display: flex;
  flex-flow: wrap;
  justify-content: start;
  flex: 1.6 1 0%;
  min-width: 350px;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px;
}

.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}

.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}

.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
}

.line {
  width: 1px;
  background-color: var(--el-border-color);
}

.el-avatar {
  --el-avatar-size: 30px;
}
</style>